.create_m_trader_title {
  color: #3B3C40;
  font-family: "CourierBold", sans-serif;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -1px;
  padding-bottom: 40px;
  padding-top: 10px;
}

.wrapper_create_m_trader {
  display: flex;
  & > .col_item {
    flex-grow: 1;
    & > .block_item {
      margin: 0 0 20px 0;
      display: flex;
      flex-direction: column;
      & > p, & > .switch_signal_wrapper > p {
        color: #171717;
        font-family: "CourierBold", sans-serif;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: -0.5px;
        line-height: 17px;
        text-transform: uppercase;
      }
      & > div {
        display: flex;
        justify-content: space-between;
        min-height: 25px;
        margin: 4px 0;
        align-items: center;
        width: 330px;
        & > .custom_input_wrapper {
          width: 145px;
          margin-top: 0 !important;
          .custom_input {
            width: auto;
            border: none;
          }
        }
        & > label {
          color: #171717;
          font-family: "CourierRegular", sans-serif;
          font-size: 14px;
          letter-spacing: -0.5px;
          line-height: 17px;
          display: flex;
          align-items: inherit;
        }
        input {
          border: none;
          padding: 6px 0;
          border-bottom: 1px solid #C8C8C8;
          color: #171717 !important;
          font-family: "CourierRegular", sans-serif;
          font-size: 14px !important;
          letter-spacing: -0.5px;
          line-height: 17px;
          width: 145px;
        }
        .non_edit_value{
          display: flex;
          font-size: 14px;
          letter-spacing: -0.5px;
          line-height: 17px;
          padding: 5px 0;
          border-bottom: 1px solid #C8C8C8;
          &.width_145 {
            width: 145px;
          }
        }
        .non_edit_signal {
          display: flex;
          font-size: 14px;
          width: 145px;
          letter-spacing: -0.5px;
          line-height: 17px;
        }
        select {
          padding: 6px;
          border-bottom: 1px solid #C8C8C8;
          color: rgb(158, 158, 158) !important;
          font-family: "CourierRegular", sans-serif;
          font-size: 14px;
          letter-spacing: -0.5px;
          line-height: 17px;
          width: 145px;
        }
        & > .group_input {
          display: flex;
          width: 145px;
          & > .custom_input_wrapper {
            margin: 0 !important;
            input {
              border-bottom: 1px solid #C8C8C8 !important;
            }
            & > div{
              border: none !important;
              padding: 0 !important;
            }
          }
          .custom_input {
            width: 68px;
            margin-top: 0 !important;
            input {
              border: none;
              margin: 0;
              padding: 0;
            }
          }
        }
      }
      .switch_block {
        display: flex;
        justify-content: flex-start;
        .custom_radio_label{
          & > span:last-child{
            font-family: "CourierRegular", sans-serif;
            font-weight: 600;
          }
        }
      }
    }
  }
  .switch_signal{
    width: 145px;
    .custom_radio_group{
      label{
        & > span:last-child{
          font-family: "CourierRegular", sans-serif;
          font-size: 14px;
          letter-spacing: -0.5px;
        }
      }
    }
  }
}

.form_create_m_trader {
  .btn_wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
    padding-right: 60px;
  }

  .create_btn {
    color: #4A52CB;
    font-size: 20px;
    letter-spacing: -0.63px;
    font-family: "CourierBold", sans-serif;

    line-height: 24px;
    display: flex;
    align-items: center;
    background: transparent;
    cursor: pointer;
    text-transform: uppercase;

    img {
      margin-left: 15px;
    }
  }
}