.manual_select_wrapper {
  display: flex;
  align-items: center;
  position: relative;
  & > span {
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 17px;
    opacity: 0.5;
    margin-right: 16px;
  }
  .manual_select {
    font-family: "CourierRegular", sans-serif;
    user-select: none;
    cursor: pointer;
    color: #C8C8C8;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 17px;
    border-bottom: 1px solid #C8C8C8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 94px;
    .full_opacity {
      color: #171717;
    }
    & > img {
      transform: scale(0.8) rotate(90deg);
      cursor: pointer;
      -webkit-transition: all 0.3s linear;
      -moz-transition: all 0.3s linear;
      -ms-transition: all 0.3s linear;
      -o-transition: all 0.3s linear;
      transition: all 0.3s linear;
      &.rotated {
        transform: scale(0.8) rotate(270deg);
      }
    }
  }
  .manual_selector {
    position: absolute;
    right: 0;
    top: 42px;
    width: 145px;
    box-sizing: border-box;
    padding: 7px 16px;
    display: none;
    background-color: #FFFFFF;
    box-shadow: 0 2px 8px -2px rgba(0,0,0,0.5);
    z-index: 1000;
    &.is_open {
      display: flex;
      flex-flow: column;
    }
    & > div {
      display: flex;
      align-items: center;
      padding: 10px 0;
      cursor: pointer;
      .unchecked {
        height: 12px;
        width: 12px;
        border: 1px solid #171717;
        opacity: 0.3;
      }
      .checked {
        height: 14px;
        width: 14px;
      }
      .tag_name {
        font-size: 14px;
        letter-spacing: -0.5px;
        line-height: 18px;
        font-family: "CourierRegular", sans-serif;
        margin-left: 8px;
        &.selected {
          font-family: "CourierBold", sans-serif;
        }
      }
    }
  }
}