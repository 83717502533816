.creation_info{
  display: flex;
  justify-content: space-between;
  position: relative;
  input{
    color: #171717;
  }
  & > form{
    width: 100%;
    display: flex;
    >div:nth-child(2){
      margin: 0 60px;
    }
    >div{
      width: 33.3%;
      display: flex;
      flex-flow: column;
      >div{
        padding-bottom: 20px;
        display: flex;
        flex-flow: column;
        .title{
          font-family:"CourierBold",sans-serif;
          font-size: 14px;
          letter-spacing: -0.5px;
          padding-bottom: 10px;
          margin: 0;
        }
        >div{
          display: flex;
          align-items: center;
          span{
            display: flex;
            width: 50%;
            font-size: 14px;
            letter-spacing: -0.5px;
            line-height: 17px;
          }
          p{
            width: 50%;
            display: flex;
            font-size: 14px;
            letter-spacing: -0.5px;
            line-height: 17px;
            padding: 5px 0;
            border-bottom: 1px solid #C8C8C8;
          }

          .custom_input_wrapper{
            width: 50%;
            display: flex;
            letter-spacing: -0.5px;
            line-height: 17px;
            padding: 0 !important;
            //border-bottom: 1px solid #C8C8C8;
            margin: 0 !important;
            input:disabled {
              opacity: 0.5;
            }
          }
          .custom_input_adorned_end{
            width: 100% !important;
            //border: none;
            border-bottom: 1px solid #C8C8C8 !important;
            height: 30px !important;
            font-size: 14px !important;
            padding: 0 !important;
            .custom_input_outline{
              padding: 0 !important;
            }
          }
          .select_wrapper{
            height: auto !important;
          }
          .later{
            color: #898989;
          }
          >div{
            display: flex;
            width: 50%;
            p:first-of-type, .custom_input_wrapper:first-of-type{
              margin-right: 15px !important;
              width: 50%;
              display: flex;
            }
            p{
              width: 50%;
              display: flex;
            }
          }
        }
      }
    }
    .switch_block{
      display: flex;
      width: 100%;
      label{
        cursor: pointer;
        margin-right: 30px;
        display: flex;
        font-family:"CourierBold",sans-serif;
        align-items: center;
        span{
          padding: 0;
        }

      }
    }

    .creation_info_btn{
      color: #4a52cb;
      font-size: 20px;
      letter-spacing: -.63px;
      font-family: CourierBold,sans-serif;
      line-height: 24px;
      display: flex;
      align-items: center;
      background: transparent;
      cursor: pointer;
      text-transform: uppercase;
      position: absolute;
      bottom: -40px;
      right: 0;
      img{
        margin-left: 15px;
      }
    }


    .testClass{
      //background: lightgrey !important;
      width: 100% !important;
      height: auto !important;
      .custom_radio_group{
        display: flex !important;
        flex-direction: column !important;
        > label{
          height: 31px !important;
          > span{
            width: auto !important;
          }
        }
      }
      .custom_input_wrapper{
        width: 100% !important;
      }
    }
  }
  .signal_wrapper{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    .status{
      height: 8px;
      width: 8px;
      min-width: 8px;
      min-height: 8px;
      border-radius: 50%;
      padding: 0;
      margin: 0 5px;
    }
    .canceled{
      background:#D0021B;
    }
    .awaiting{
      background: #BCBCBC;
    }
    .done{
      background: #58BC7F;
    }
  }

  .switch_signal{
    width: 145px;
    .custom_radio_group{
      label{
        & > span:last-child{
          font-family: Courier;
          font-size: 14px;
          letter-spacing: -0.5px;
        }
      }
    }
    .custom_radio_group{
      label{
        width: 45px;
        margin-right: 25px;
        & > span:first-child{
          padding: 0;
          margin-left: 10px;
          margin-bottom: 4px;
        }
      }
    }
  }

}

