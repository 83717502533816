.m_traders_table{
  .transactions_columns {
    width: 100%;
    .table_header {
      .row_item {
        display: flex;
        align-items: center;
        cursor: pointer;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        .down{
          margin-left: 5px;
          margin-bottom: -1px;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 6px solid #ffffff;
        }
        .up{
          margin-top: -1px;
          margin-left: 5px;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 6px solid #ffffff;
        }
      }
    }
    .table_body{
      .table_row{
        height: 40px;
        &:nth-of-type(2n) {
          background-color: transparent!important;
        }
        .row_item{
          color: #171717;
        }
      }
    }
    .table_row {
      & > :first-child {
        width: 100%!important;
        justify-content: flex-start!important;
      }
    }
    .status{
      display: flex;
      align-items: center;
      color: #4A52CB;
      font-family: "CourierBold",sans-serif;
      font-size: 14px;
      letter-spacing: -0.5px;
      line-height: 17px;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
      span{
        height: 8px;
        width: 8px;
        min-width: 8px;
        min-height: 8px;
        margin-right: 10px;
        border-radius: 50%;
      }
      .canceled{
        background: #e11e1e;
      }
      .awaiting{
        background: #BCBCBC;
      }
      .done{
        background: #58BC7F;
      }
    }
  }
}
