.ip_traders_table{
  .transactions_columns {
    width: 100%;
    .table_header {
      .table_row {
        height: 36px;
      }
      .row_item {
        display: flex;
        align-items: center;
        cursor: pointer;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        .down{
          margin-left: 5px;
          margin-bottom: -1px;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 6px solid #ffffff;
        }
        .up{
          margin-top: -1px;
          margin-left: 5px;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 6px solid #ffffff;
        }
      }
    }
    .table_body{
      .table_row{
        height: 40px;
        &:nth-of-type(2n) {
          background-color: transparent!important;
        }
        .row_item{
          color: #171717;
        }
      }
    }
    .table_row {
      & > :first-child {
        width: 15%!important;
      }
      & > :nth-child(2) {
        width: 6%!important;
        display: flex;
        justify-content: flex-end;
      }
      & > :nth-child(3) {
        width: 6%!important;
        display: flex;
        justify-content: flex-end;
      }
      & > :nth-child(4) {
        width: 6%!important;
        display: flex;
        justify-content: flex-end;
        margin-right: 2%;
      }
      & > :nth-child(5) {
        width: 6%!important;
        display: flex;
        justify-content: flex-end;
      }
      & > :nth-child(6) {
        width: 9%!important;
        display: flex;
        justify-content: flex-end;
      }
      & > :nth-child(7) {
        display: flex;
        justify-content: flex-end;
        width: 9%!important;
      }
      & > :nth-child(8) {
        display: flex;
        justify-content: flex-end;
        width: 9%!important;
      }
      & > :nth-child(9) {
        display: flex;
        justify-content: flex-end;
        width: 9%!important;
      }
      & > :nth-child(10) {
        display: flex;
        justify-content: flex-end;
        width: 9%!important;
      }
      & > :nth-child(11) {
        display: flex;
        justify-content: flex-end;
        width: 9%!important;
      }
      & > :last-child {
        width: 7%!important;
        display: flex;
        justify-content: flex-end;
        button{
          display: flex;
          background: transparent;
        }
      }
    }
    .table_row_server {
      & > :first-child {
        width: 21%!important;
      }
      & > :nth-child(2) {
        width: 21%!important;
      }
      & > :nth-child(3) {
        width: 13%!important;
      }
      & > :nth-child(4) {
        width: 8%!important;
        margin-right: 2%;
      }
      & > :nth-child(5) {
        width: 13%!important;
        justify-content: left!important;
      }
      & > :nth-child(6) {
        width: 13%!important;
      }
      & > :nth-child(7) {
        width: 13%!important;
      }
      & > :nth-child(8) {
        width: 10%!important;
      }
      & > :nth-child(9) {
        width: 10%!important;
      }
      & > :nth-child(10) {
        width: 17%!important;
        font-family: "CourierBold", sans-serif;
        display: flex;
        justify-content: flex-end!important;
        padding-right: 13px;
        color: #4A52CB!important;
        .copy_icon {
          cursor: pointer;
          -webkit-transition: all 0.3s linear;
          -moz-transition: all 0.3s linear;
          -ms-transition: all 0.3s linear;
          -o-transition: all 0.3s linear;
          transition: all 0.3s linear;
          &:hover {
            opacity: 0.5;
          }
          img {
            margin-right: 8px;
          }
        }
      }
    }
    .block_row{
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      span{
        display: flex;
        justify-content: flex-end;
      }
      p{
        color: #9B9B9B;
        display: flex;
        justify-content: flex-end;
      }

    }
    .status{
      display: flex;
      align-items: center;
      color: #4A52CB;
      font-family: "CourierBold",sans-serif;
      font-size: 14px;
      letter-spacing: -0.5px;
      line-height: 17px;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
      span{
        height: 8px;
        width: 8px;
        min-width: 8px;
        min-height: 8px;
        margin-right: 10px;
        border-radius: 50%;
      }
      .canceled{
        background: #e11e1e;
      }
      .awaiting{
        background: #BCBCBC;
      }
      .done{
        background: #58BC7F;
      }
    }
    .server_status{
      display: flex;
      align-items: center;
      color: #3B3C40;
      font-family: "CourierBold",sans-serif;
      letter-spacing: -0.5px;
      span{
        height: 8px;
        width: 8px;
        min-width: 8px;
        min-height: 8px;
        margin-right: 10px;
        border-radius: 50%;
      }
      .creating{
        background-color: #66C6FF;
      }
      .created{
        background-color: #58BC7F;
      }
      .error{
        background-color: #D0021B;
      }
      .alert{
        background-color: #FFB232;
      }
      .deleting{
        background-color: #BCBCBC;
      }
    }

    .table_total{
      .table_row{
        .row_item{
          font-family: "CourierBold",sans-serif;
        }
      }
    }
  }
}
