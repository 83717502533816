
@font-face {
  font-family: "CourierBold";
  src: url('../assets/fonts/courbd.ttf') format("truetype");
}

@font-face {
  font-family: "CourierRegular";
  src: url('../assets/fonts/cour.ttf') format("truetype");
}


@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap&subset=cyrillic-ext');

@import "./reset";
@import "./base";
@import "./animations";