@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap&subset=cyrillic-ext);
@font-face {
  font-family: "CourierBold";
  src: url(/static/media/courbd.47eea0ae.ttf) format("truetype"); }

@font-face {
  font-family: "CourierRegular";
  src: url(/static/media/cour.5bc234e3.ttf) format("truetype"); }

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, dialog, div, dl, dt, em, embed, fieldset, figcaption, figure, font, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, hr, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, meter, nav, object, ol, output, p, pre, progress, q, rp, rt, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%; }

article, aside, details, figcaption, figure, footer, header, menu, nav, section {
  display: block; }

b, strong {
  font-weight: bold; }

img {
  color: transparent;
  font-size: 0;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic; }

li {
  display: list-item;
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th, td, caption {
  font-weight: normal;
  vertical-align: top;
  text-align: left; }

q {
  quotes: none; }

q:before, q:after {
  content: '';
  content: none; }

sub, sup, small {
  font-size: 75%; }

sub, sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

svg {
  overflow: hidden; }

* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none; }

.clear:after, .clear:before {
  clear: both;
  display: block;
  zoom: 1;
  content: ''; }

body {
  color: #171717;
  font-family: 'CourierRegular',sans-serif; }

.blue_text {
  color: #4A52CB; }

.content_block {
  box-sizing: border-box;
  margin: 24px auto 40px;
  padding-left: 8px;
  padding-right: 8px;
  max-width: 1634px;
  width: 100%; }
  @media screen and (min-width: 62em) {
    .content_block {
      margin-top: 40px;
      margin-bottom: 80px;
      padding-left: 32px;
      padding-right: 32px; } }

a {
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  font-family: 'CourierRegular',sans-serif; }

button {
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  font-family: 'CourierRegular',sans-serif; }

.table_container .table_header .table_header {
  display: flex; }

.table_header .row_item, .table_footer .row_item {
  color: #FFFFFF;
  font-size: 14px;
  letter-spacing: -0.5px; }

.table_header .table_row, .table_footer .table_row {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 20px;
  background: #4A52CB; }

.table_body.table_total .row_item {
  font-size: 14px; }

.table_body .row_item {
  color: #171717;
  font-size: 16px;
  letter-spacing: -0.5px; }
  .table_body .row_item:first-child {
    font-size: 14px; }
  @media screen and (min-width: 48em) {
    .table_body .row_item {
      font-size: 14px; } }

.table_body .table_row {
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none; }
  .table_body .table_row:nth-of-type(2n) {
    background-color: #F0F3FE; }

.go_back {
  color: #4A52CB;
  font-family: "CourierBold",sans-serif;
  font-size: 14px;
  letter-spacing: -0.5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }
  .go_back img {
    margin-right: 5px; }
  .go_back:hover {
    text-decoration: underline; }

.execute_button {
  color: #4A52CB;
  font-family: "CourierBold",sans-serif;
  font-size: 14px;
  letter-spacing: -0.5px;
  margin-left: 20px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear; }
  .execute_button:hover {
    opacity: 0.7; }
  .execute_button.disabled {
    opacity: 0.5;
    cursor: default; }
    .execute_button.disabled:hover {
      opacity: 0.5; }

.loading_word::after {
  display: inline-block;
  -webkit-animation: dotty steps(1, end) 0.2s infinite;
          animation: dotty steps(1, end) 0.2s infinite;
  content: ''; }

@-webkit-keyframes dotty {
  0% {
    content: ''; }
  25% {
    content: '.'; }
  50% {
    content: '..'; }
  75% {
    content: '...'; }
  100% {
    content: ''; } }

@keyframes dotty {
  0% {
    content: ''; }
  25% {
    content: '.'; }
  50% {
    content: '..'; }
  75% {
    content: '...'; }
  100% {
    content: ''; } }

.p_with_opacity p {
  color: #A5A5A5 !important; }

@media screen and (max-width: 47.9375em) {
  .hide_on_mob {
    display: none !important; } }

.header_wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 8px;
  padding-right: 8px;
  max-width: 1634px;
  width: 100%;
  min-height: 60px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  @media screen and (min-width: 48em) {
    .header_wrapper {
      min-height: 100px; } }
  @media screen and (min-width: 62em) {
    .header_wrapper {
      padding-left: 32px;
      padding-right: 32px; } }
  .header_wrapper .logo {
    font-size: 18px;
    letter-spacing: -0.64px;
    line-height: 22px;
    display: flex;
    text-decoration: none; }
    .header_wrapper .logo div {
      font-family: "CourierBold", sans-serif;
      color: #4A52CB; }
    .header_wrapper .logo span {
      font-family: "CourierBold", sans-serif;
      color: #171717; }
  .header_wrapper .log_out {
    font-size: 14px;
    display: flex;
    align-items: center;
    background: transparent;
    cursor: pointer;
    letter-spacing: -0.5px;
    margin-left: auto; }
    @media screen and (min-width: 48em) {
      .header_wrapper .log_out img {
        margin-right: 10px; } }
    .header_wrapper .log_out span {
      display: none; }
      @media screen and (min-width: 48em) {
        .header_wrapper .log_out span {
          display: inline-block; } }
  @media screen and (min-width: 62em) {
    .header_wrapper .header_wrapper_menu {
      margin-left: 70px; } }
  .header_wrapper .header_wrapper_menu .active {
    opacity: 1; }
  .header_wrapper .header_wrapper_menu > a {
    font-family: "CourierRegular", sans-serif;
    margin-left: 24px;
    background: none;
    text-decoration: none;
    color: #171717;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 17px;
    font-weight: bold;
    opacity: 0.3; }
    @media screen and (min-width: 48em) {
      .header_wrapper .header_wrapper_menu > a {
        margin-left: 30px; } }

.preloader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0px);
          transform: translate3d(-50%, -50%, 0px); }

.preloader {
  width: 100px;
  height: 100px;
  border-left: 2px solid red;
  border-radius: 50%;
  -webkit-animation: round1 1.3s linear infinite, colorAnimation 12s ease-out  infinite;
          animation: round1 1.3s linear infinite, colorAnimation 12s ease-out  infinite; }

@-webkit-keyframes round1 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes round1 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: center center;
            transform-origin: center center; }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes colorAnimation {
  0% {
    border-left: 2px solid #3498db; }
  15% {
    border-left: 2px solid #18bc9c; }
  30% {
    border-left: 2px solid #58C433; }
  45% {
    border-left: 2px solid #FF6853; }
  60% {
    border-left: 2px solid #e62739; }
  75% {
    border-left: 2px solid #f14ca7; }
  90% {
    border-left: 2px solid #9068be; } }

@keyframes colorAnimation {
  0% {
    border-left: 2px solid #3498db; }
  15% {
    border-left: 2px solid #18bc9c; }
  30% {
    border-left: 2px solid #58C433; }
  45% {
    border-left: 2px solid #FF6853; }
  60% {
    border-left: 2px solid #e62739; }
  75% {
    border-left: 2px solid #f14ca7; }
  90% {
    border-left: 2px solid #9068be; } }

.trk_one {
  overflow: auto; }

.trk_table_row {
  min-width: 465px;
  width: 100%; }
  @media screen and (min-width: 48em) {
    .trk_table_row {
      min-width: 900px; } }
  @media screen and (max-width: 47.9375em) {
    .trk_table_row .row_item {
      padding-left: 8px;
      padding-right: 8px;
      width: 20%; } }
  .trk_table_row > :first-child {
    word-break: break-word; }
    @media screen and (min-width: 48em) {
      .trk_table_row > :first-child {
        margin-left: 1%;
        width: 13%; } }
    .trk_table_row > :first-child a {
      color: #4A52CB;
      font-family: "CourierBold", sans-serif;
      letter-spacing: -0.5px;
      text-decoration: none; }
      .trk_table_row > :first-child a:hover {
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear;
        text-decoration: underline; }
  .trk_table_row > :nth-child(2) {
    display: flex;
    justify-content: flex-end; }
    @media screen and (min-width: 48em) {
      .trk_table_row > :nth-child(2) {
        width: 10%; } }
  .trk_table_row > :nth-child(3) {
    display: flex;
    justify-content: flex-end; }
    @media screen and (min-width: 48em) {
      .trk_table_row > :nth-child(3) {
        width: 10%; } }
  .trk_table_row > :nth-child(4) {
    display: flex;
    justify-content: flex-end; }
    @media screen and (min-width: 48em) {
      .trk_table_row > :nth-child(4) {
        margin-right: 2%;
        width: 9%; } }
  .trk_table_row > :nth-child(5) {
    display: flex;
    justify-content: flex-end;
    text-align: right; }
    @media screen and (min-width: 48em) {
      .trk_table_row > :nth-child(5) {
        justify-content: flex-start;
        width: 8%;
        text-align: left; } }
  .trk_table_row > :nth-child(6) {
    width: 7%;
    display: flex;
    justify-content: flex-end; }
  .trk_table_row > :nth-child(7) {
    width: 10%;
    display: flex;
    justify-content: flex-end; }
  .trk_table_row > :nth-child(8) {
    width: 10%;
    display: flex;
    justify-content: flex-end; }
  .trk_table_row > :nth-child(9) {
    width: 10%;
    display: flex;
    justify-content: flex-end; }
  .trk_table_row > :last-child {
    width: 9%;
    display: flex;
    justify-content: flex-end;
    margin-right: 1%; }
  @media screen and (max-width: 47.9375em) {
    .trk_table_row > :nth-child(n+6) {
      display: none !important; } }

.select_component {
  height: 100%; }
  .select_component ~ .date_wrapper {
    margin-left: 20px; }
  .select_component.select--is-disabled {
    opacity: 0.5; }
  .select_component .select__control--is-focused {
    box-shadow: none;
    border: 1px solid #65A3E8; }
    .select_component .select__control--is-focused .indicator_active svg {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
  .select_component .select__control {
    height: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #C8C8C8;
    color: #1A1A1A !important;
    font-family: "GothaProMed", sans-serif !important;
    font-size: 10px;
    border-radius: 0; }
    .select_component .select__control .select__value-container {
      padding: 0;
      font-family: "CourierRegular", sans-serif;
      font-size: 14px; }
    .select_component .select__control:hover {
      cursor: pointer;
      border: none;
      border-bottom: 1px solid #C8C8C8; }
    .select_component .select__control .select__placeholder {
      color: rgba(127, 143, 164, 0.5);
      margin: 0; }
    .select_component .select__control .select__single-value {
      color: #1A1A1A; }
    .select_component .select__control .select__input {
      color: #1A1A1A;
      font-family: "GothaProReg", sans-serif;
      font-size: 10px; }
      .select_component .select__control .select__input input {
        font-size: 12px; }
    .select_component .select__control .select__indicator-separator {
      background: none; }
    .select_component .select__control .select_indicator {
      display: flex;
      align-items: center;
      justify-content: center; }
      .select_component .select__control .select_indicator svg {
        fill: #1A1A1A;
        font-size: 14px; }
    .select_component .select__control .select__loading-indicator span {
      background-color: #7F8FA4; }
    .select_component .select__control .select_close_btn {
      display: flex;
      align-items: center;
      padding: 8px;
      justify-content: center;
      cursor: pointer; }
      .select_component .select__control .select_close_btn svg {
        fill: #1A1A1A;
        font-size: 14px; }
  .select_component .select__menu {
    z-index: 9999;
    box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.5);
    border-radius: 0; }
    .select_component .select__menu .select__menu-list {
      font-family: 'CourierRegular', sans-serif;
      font-size: 14px;
      color: #9B9B9B;
      background: transparent; }
      .select_component .select__menu .select__menu-list .select__option {
        background: transparent;
        color: #171717;
        cursor: pointer; }
        .select_component .select__menu .select__menu-list .select__option:hover {
          background-color: #F0F3FE; }
      .select_component .select__menu .select__menu-list .status_select {
        padding: 0 12px;
        display: flex;
        align-items: center;
        background: transparent; }
        .select_component .select__menu .select__menu-list .status_select:hover {
          background: transparent; }

.select_wrapper {
  height: 32px;
  width: 145px; }
  .select_wrapper.error {
    border-bottom: 1px solid red !important; }

.status_select {
  display: flex;
  align-items: center; }

.custom_input_select {
  border: 1px solid red !important; }

.dashboard_wrapper .title_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .dashboard_wrapper .title_wrapper .title {
    padding-bottom: 30px;
    color: #3B3C40;
    font-family: "CourierBold", sans-serif;
    font-size: 32px;
    letter-spacing: -1px;
    line-height: 38px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: flex;
    flex-direction: column;
    grid-gap: 16px 100px;
    gap: 16px 100px; }
    @media screen and (min-width: 48em) {
      .dashboard_wrapper .title_wrapper .title {
        flex-direction: row;
        align-items: center; } }
    .dashboard_wrapper .title_wrapper .title .trk_tabs {
      display: flex; }
      .dashboard_wrapper .title_wrapper .title .trk_tabs button {
        color: #C8C8C8;
        font-size: 14px;
        line-height: 13px;
        margin-right: 16px;
        font-family: "CourierBold", sans-serif;
        background: transparent;
        cursor: pointer;
        text-transform: uppercase; }
        @media screen and (min-width: 48em) {
          .dashboard_wrapper .title_wrapper .title .trk_tabs button {
            margin-right: 30px; } }
        .dashboard_wrapper .title_wrapper .title .trk_tabs button:hover {
          color: #171717; }
      .dashboard_wrapper .title_wrapper .title .trk_tabs .active {
        color: #171717;
        position: relative; }
        .dashboard_wrapper .title_wrapper .title .trk_tabs .active:before {
          position: absolute;
          content: "";
          left: 0;
          bottom: -10px;
          width: 100%;
          height: 2px;
          background: #4A52CB; }
  .dashboard_wrapper .title_wrapper .tags_wrapper {
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: 30px; }
    .dashboard_wrapper .title_wrapper .tags_wrapper > span {
      font-size: 14px;
      letter-spacing: -0.5px;
      line-height: 17px;
      opacity: 0.5;
      margin-right: 16px; }
    .dashboard_wrapper .title_wrapper .tags_wrapper .tags_select {
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      color: #C8C8C8;
      font-size: 14px;
      letter-spacing: -0.5px;
      line-height: 17px;
      border-bottom: 1px solid #C8C8C8;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content; }
      .dashboard_wrapper .title_wrapper .tags_wrapper .tags_select .full_opacity {
        color: #171717; }
      .dashboard_wrapper .title_wrapper .tags_wrapper .tags_select > img {
        -webkit-transform: scale(0.8) rotate(90deg);
                transform: scale(0.8) rotate(90deg);
        cursor: pointer;
        -webkit-transition: all 0.3s linear;
        transition: all 0.3s linear; }
        .dashboard_wrapper .title_wrapper .tags_wrapper .tags_select > img.rotated {
          -webkit-transform: scale(0.8) rotate(270deg);
                  transform: scale(0.8) rotate(270deg); }
    .dashboard_wrapper .title_wrapper .tags_wrapper .tags_selector {
      position: absolute;
      right: 0;
      top: 42px;
      width: 145px;
      box-sizing: border-box;
      padding: 7px 16px;
      display: none;
      background-color: #FFFFFF;
      box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.5);
      z-index: 1000; }
      .dashboard_wrapper .title_wrapper .tags_wrapper .tags_selector.is_open {
        display: flex;
        flex-flow: column; }
      .dashboard_wrapper .title_wrapper .tags_wrapper .tags_selector > div {
        display: flex;
        align-items: center;
        padding: 10px 0;
        cursor: pointer; }
        .dashboard_wrapper .title_wrapper .tags_wrapper .tags_selector > div .unchecked {
          height: 12px;
          width: 12px;
          border: 1px solid #171717;
          opacity: 0.3; }
        .dashboard_wrapper .title_wrapper .tags_wrapper .tags_selector > div .checked {
          height: 14px;
          width: 14px; }
        .dashboard_wrapper .title_wrapper .tags_wrapper .tags_selector > div .tag_name {
          font-size: 14px;
          letter-spacing: -0.5px;
          line-height: 18px;
          font-family: "CourierRegular", sans-serif;
          margin-left: 8px; }
          .dashboard_wrapper .title_wrapper .tags_wrapper .tags_selector > div .tag_name.selected {
            font-family: "CourierBold", sans-serif; }

.dashboard_wrapper .transactions_columns {
  width: 100%; }
  .dashboard_wrapper .transactions_columns .table_header {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .dashboard_wrapper .transactions_columns .table_header .table_row {
      height: 36px; }
    .dashboard_wrapper .transactions_columns .table_header .row_item {
      display: flex;
      align-items: center;
      cursor: pointer;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear; }
      .dashboard_wrapper .transactions_columns .table_header .row_item p {
        display: flex;
        align-items: center; }
      .dashboard_wrapper .transactions_columns .table_header .row_item .down {
        margin-left: 5px;
        margin-bottom: -1px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 6px solid #ffffff; }
      .dashboard_wrapper .transactions_columns .table_header .row_item .up {
        margin-top: -1px;
        margin-left: 5px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 6px solid #ffffff; }
        .dashboard_wrapper .transactions_columns .table_header .row_item .up.no_display {
          opacity: 0; }
  .dashboard_wrapper .transactions_columns .table_body .table_row {
    height: 40px; }
    .dashboard_wrapper .transactions_columns .table_body .table_row .row_item {
      color: #171717; }
  .dashboard_wrapper .transactions_columns .block_row {
    display: flex;
    flex-flow: column;
    justify-content: flex-end; }
    .dashboard_wrapper .transactions_columns .block_row > span {
      display: flex;
      justify-content: flex-end; }
    .dashboard_wrapper .transactions_columns .block_row > p {
      display: flex;
      justify-content: flex-end; }
  .dashboard_wrapper .transactions_columns .block_row_flex_start {
    display: flex;
    flex-flow: column; }
  .dashboard_wrapper .transactions_columns .trader_status {
    display: flex;
    align-items: center;
    color: #3B3C40;
    font-family: "CourierBold", sans-serif;
    letter-spacing: -0.5px; }
    .dashboard_wrapper .transactions_columns .trader_status span {
      min-width: 8px;
      min-height: 8px;
      height: 8px;
      width: 8px;
      margin-right: 10px;
      border-radius: 50%; }
    .dashboard_wrapper .transactions_columns .trader_status .on {
      background-color: #58BC7F; }
    .dashboard_wrapper .transactions_columns .trader_status .alert {
      background-color: #D0021B; }
    .dashboard_wrapper .transactions_columns .trader_status .off {
      background-color: #BCBCBC; }
  .dashboard_wrapper .transactions_columns .table_total .table_row .row_item, .dashboard_wrapper .transactions_columns .table_total .table_row p, .dashboard_wrapper .transactions_columns .table_total .table_row span {
    font-family: "CourierBold", sans-serif; }

.inp_radio {
  width: 15px;
  height: 15px;
  padding-right: 8px; }
  .inp_radio > input[type="radio"] {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    border: none !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAATBJREFUKBWNUrGOgkAQdacjodHuahONIaGSQHIFtV+gP3Dlfc39xN0XWFuYQLAiIUYTKnptSOjA95T1Fgpxk2VnZ96bx8yOGvVWlmWTsizDpmmmDCmlctu2d47jXEyomJckSTYgnUD6AuGDmzZ9jJlYpS9RFP3CXgM8933/rP084zie4dgiSRIEwX8CZkMwL4rCMgmmzRiSN1pZpWk6rqqKCp99JZNIu1Xeo+a5gBTCdxgikUgMf5fNE9TE7h0ZeGcBfwR52unqO0SNEbBzXBbaMXQCv4BqLpZl7QBetoW/5BEDkseBENd1ryLyjUynoedA1i2xnKLOADAbgqt+h6nExIj96QF4EvmPfNy6rn9gHrB1p1n/kkqe53G67qtDpKcdiBDq9yFn89gHlvSgPL4379ScoiQDCYMAAAAASUVORK5CYII=) no-repeat; }
  .inp_radio > input[type="radio"]:checked {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border: none !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAZRJREFUKBWFUjFPgjEQvSsCgpNOMDiRSIjGGAOTA7tMRgnq4ObozGDiYOLg7D9wUAgaJ3R2cMIYYzREEiYHmXQS8AN69pWUfDhok369vnvX++7dMf1a+fzLTIe6WRFJwMXMzQhN3lYq8x9+qvJfchsPm+1B51VrvStCcWzYwODzc9ldVtdrJRIqhIPB5FV5qeFwnGuFxzmv37sRotr1ZcY+YAPtazI4iqrYQqUy2/EHOTuff4t8DVptVoGt6sVyiXPbT9PU9Rqh4MQKMpkaQ23dOTS/uYMgZjqNqsiBqdGzmXv9u2ggklTq28sSyb37vWGQFA0WxzYiFYHhEXCEpQbxlBZKEKs6HFgu0/A2/I5jXIfiY6r6yf/ZSjE1SXTKEVGTs905jkkKvVU6HLo1EqRROIgQwjiODfaODRsYfOCwcAYDMWqH6MH5VCAW/asdbd16Jg7sox22RhjEVIbDZUYGt4ChhxgAyzWO0eSAZAdB6xO0Z6S0rZ/TpNSeCwJ3LNAGm4FAb22bDADxoEP1bPETfrd+AD9lyknihmsMAAAAAElFTkSuQmCC) no-repeat; }

.custom_radio {
  display: flex !important;
  flex-direction: row !important; }

.custom_radio_group {
  flex-direction: row !important; }

.my_radio {
  padding: 0 !important;
  margin-left: 13px !important;
  padding-right: 7px !important; }

.custom_input_wrapper {
  width: 100%;
  max-width: 300px;
  margin-top: 30px !important; }
  .custom_input_wrapper .custom_input_label {
    font-family: "CourierRegular", sans-serif;
    color: #C2D1D9;
    font-size: 14px;
    -webkit-transform: translate(0, 13px) scale(1);
            transform: translate(0, 13px) scale(1);
    font-weight: 400; }
    .custom_input_wrapper .custom_input_label.custom_input_label_focused {
      color: rgba(255, 255, 255, 0.72); }
    .custom_input_wrapper .custom_input_label.custom_input_label_active {
      color: #01397C;
      -webkit-transform: translate(0, -6px) scale(0.75);
              transform: translate(0, -6px) scale(0.75); }
    .custom_input_wrapper .custom_input_label.custom_input_label_error {
      color: #ff0000; }
  .custom_input_wrapper .custom_input {
    font-family: "CourierRegular", sans-serif;
    background-color: #FFFFFF;
    color: #01397C;
    font-size: 24px;
    max-height: 40px;
    width: 500px;
    border-bottom: 1px solid #C8C8C8; }
    .custom_input_wrapper .custom_input:focus {
      background-color: #FFFFFF; }
    .custom_input_wrapper .custom_input input {
      padding: 12px 0; }
    .custom_input_wrapper .custom_input .custom_input_outline {
      border: none;
      border-radius: 0; }
      .custom_input_wrapper .custom_input .custom_input_outline legend {
        line-height: 0 !important; }
    .custom_input_wrapper .custom_input.custom_input_focused {
      background-color: #FFFFFF;
      border-radius: 0; }
      .custom_input_wrapper .custom_input.custom_input_focused .custom_input_outline {
        border: 1px solid #01397C;
        border-width: 0; }
    .custom_input_wrapper .custom_input.custom_input_error .custom_input_outline, .custom_input_wrapper .custom_input.custom_input_error:hover .custom_input_outline {
      border-bottom: 1px solid #ff0000 !important; }
    .custom_input_wrapper .custom_input.custom_input_disabled {
      color: #334150; }
  .custom_input_wrapper .custom_input_adorned_end {
    padding-right: 5px; }
    .custom_input_wrapper .custom_input_adorned_end > div:last-of-type {
      margin-right: 8px;
      display: none; }
      .custom_input_wrapper .custom_input_adorned_end > div:last-of-type span {
        color: #C2D1D9;
        position: absolute; }
        .custom_input_wrapper .custom_input_adorned_end > div:last-of-type span.error_empty {
          right: 0;
          width: 100%;
          height: 15px;
          top: -10px; }
      .custom_input_wrapper .custom_input_adorned_end > div:last-of-type svg {
        fill: #ff0000;
        cursor: pointer; }
      .custom_input_wrapper .custom_input_adorned_end > div:last-of-type .password_type_btn {
        padding: 0;
        width: 25px;
        height: 25px; }
        .custom_input_wrapper .custom_input_adorned_end > div:last-of-type .password_type_btn:hover {
          background-color: transparent; }
        .custom_input_wrapper .custom_input_adorned_end > div:last-of-type .password_type_btn svg {
          fill: #C2D1D9; }
  .custom_input_wrapper .custom_input_adorned_start > div:first-of-type {
    margin-right: 0; }
    .custom_input_wrapper .custom_input_adorned_start > div:first-of-type span {
      color: #C2D1D9; }

.message_popper .error_message_tooltip {
  background-color: #171717;
  word-break: break-word;
  font-size: 12px;
  letter-spacing: -0.5px;
  line-height: 16px;
  font-family: "CourierRegular", sans-serif; }

.trk_creation_info {
  display: flex;
  justify-content: space-between;
  position: relative; }
  .trk_creation_info input {
    color: #171717; }
  .trk_creation_info .execute_button {
    width: unset !important;
    cursor: not-allowed; }
    .trk_creation_info .execute_button.inactive {
      opacity: 0.5; }
  .trk_creation_info .manual_trade_input {
    width: 100% !important;
    position: relative; }
    .trk_creation_info .manual_trade_input input {
      font-family: "CourierRegular", sans-serif;
      background-color: #FFFFFF;
      color: #01397C;
      font-size: 14px;
      padding: 12px 20px 12px 0;
      max-height: 40px;
      border-bottom: 1px solid #C8C8C8;
      width: 100%; }
      .trk_creation_info .manual_trade_input input:focus {
        background-color: #FFFFFF; }
    .trk_creation_info .manual_trade_input img {
      cursor: pointer;
      position: absolute;
      right: 0;
      bottom: 12px; }
  .trk_creation_info > form {
    width: 100%;
    display: flex; }
    @media screen and (max-width: 47.9375em) {
      .trk_creation_info > form {
        flex-direction: column; } }
    @media screen and (min-width: 48em) {
      .trk_creation_info > form > div:nth-child(2) {
        margin: 0 60px; } }
    .trk_creation_info > form > div {
      display: flex;
      flex-flow: column; }
      @media screen and (min-width: 48em) {
        .trk_creation_info > form > div {
          width: 33.3%; } }
      .trk_creation_info > form > div > div {
        padding-bottom: 20px;
        display: flex;
        flex-flow: column; }
        .trk_creation_info > form > div > div .title {
          font-family: "CourierBold", sans-serif;
          font-size: 14px;
          letter-spacing: -0.5px;
          padding-bottom: 10px;
          margin: 0; }
        .trk_creation_info > form > div > div > div {
          display: flex;
          flex-wrap: wrap;
          align-items: center; }
          .trk_creation_info > form > div > div > div span {
            display: flex;
            width: 50%;
            font-size: 14px;
            letter-spacing: -0.5px;
            line-height: 17px; }
          .trk_creation_info > form > div > div > div p {
            width: 50%;
            display: flex;
            flex-wrap: wrap;
            font-size: 14px;
            letter-spacing: -0.5px;
            line-height: 17px;
            padding: 5px 0;
            border-bottom: 1px solid #C8C8C8; }
          .trk_creation_info > form > div > div > div .custom_input_wrapper {
            width: 50%;
            display: flex;
            letter-spacing: -0.5px;
            line-height: 17px;
            padding: 0 !important;
            margin: 0 !important; }
          .trk_creation_info > form > div > div > div .custom_input_adorned_end {
            width: 100% !important;
            border-bottom: 1px solid #C8C8C8 !important;
            height: 30px !important;
            font-size: 14px !important;
            padding: 0 !important; }
            .trk_creation_info > form > div > div > div .custom_input_adorned_end .custom_input_outline {
              padding: 0 !important; }
          .trk_creation_info > form > div > div > div .select_wrapper {
            height: auto !important; }
          .trk_creation_info > form > div > div > div .later {
            color: #898989; }
          .trk_creation_info > form > div > div > div > div {
            display: flex;
            width: 50%; }
            .trk_creation_info > form > div > div > div > div p:first-of-type, .trk_creation_info > form > div > div > div > div .custom_input_wrapper:first-of-type {
              margin-right: 15px !important;
              width: 50%;
              display: flex; }
            .trk_creation_info > form > div > div > div > div p {
              width: 50%;
              display: flex; }
    .trk_creation_info > form .switch_block {
      display: flex;
      width: 100%; }
      .trk_creation_info > form .switch_block.disabled {
        pointer-events: none; }
      .trk_creation_info > form .switch_block label {
        cursor: pointer;
        margin-right: 30px;
        display: flex;
        font-family: "CourierBold", sans-serif;
        align-items: center; }
        .trk_creation_info > form .switch_block label span {
          padding: 0; }
    .trk_creation_info > form .creation_info_btn {
      color: #4a52cb;
      font-size: 20px;
      letter-spacing: -.63px;
      font-family: CourierBold, sans-serif;
      line-height: 24px;
      display: flex;
      align-items: center;
      background: transparent;
      cursor: pointer;
      text-transform: uppercase;
      position: absolute;
      bottom: 0;
      right: 0; }
      @media screen and (min-width: 48em) {
        .trk_creation_info > form .creation_info_btn {
          bottom: -40px; } }
      .trk_creation_info > form .creation_info_btn img {
        margin-left: 15px; }
    .trk_creation_info > form .testClass {
      width: 100% !important;
      height: auto !important; }
      .trk_creation_info > form .testClass .custom_radio_group {
        display: flex !important;
        flex-direction: column !important; }
        .trk_creation_info > form .testClass .custom_radio_group > label {
          height: 31px !important; }
          .trk_creation_info > form .testClass .custom_radio_group > label > span {
            width: auto !important; }
      .trk_creation_info > form .testClass .custom_input_wrapper {
        width: 100% !important; }
  .trk_creation_info .signal_wrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline; }
    .trk_creation_info .signal_wrapper .status {
      height: 8px;
      width: 8px;
      min-width: 8px;
      min-height: 8px;
      border-radius: 50%;
      padding: 0;
      margin: 0 5px; }
    .trk_creation_info .signal_wrapper .canceled {
      background: #D0021B; }
    .trk_creation_info .signal_wrapper .awaiting {
      background: #BCBCBC; }
    .trk_creation_info .signal_wrapper .done {
      background: #58BC7F; }
  .trk_creation_info .switch_signal {
    width: 145px; }
    .trk_creation_info .switch_signal .custom_radio_group label > span:last-child {
      font-family: Courier;
      font-size: 14px;
      letter-spacing: -0.5px; }
    .trk_creation_info .switch_signal .custom_radio_group label {
      width: 45px;
      margin-right: 25px; }
      .trk_creation_info .switch_signal .custom_radio_group label > span:first-child {
        padding: 0;
        margin-left: 10px;
        margin-bottom: 4px; }

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 30px 0; }

.pagination-list {
  display: flex; }
  .pagination-list li {
    border-radius: 2px;
    min-width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all ease .3s;
    transition: all ease .3s; }
    .pagination-list li:hover {
      background: rgba(74, 82, 203, 0.1); }
    .pagination-list li a {
      font-family: "CourierRegular", sans-serif;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 0 5px;
      box-sizing: border-box; }

.pagination-link {
  cursor: pointer;
  background: transparent;
  color: #171717;
  text-decoration: none;
  text-align: center;
  line-height: 28px;
  height: 32px;
  min-width: 32px;
  font-size: 14px; }
  .pagination-link.pagination-link-active {
    background: #4A52CB;
    color: #ffffff; }
    .pagination-link.pagination-link-active:hover {
      opacity: 0.7; }

.pagination-ellipsis {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; }
  .pagination-ellipsis a {
    cursor: pointer;
    background: transparent;
    height: 32px;
    width: 32px;
    color: #171717;
    text-decoration: none;
    text-align: center;
    line-height: 28px; }

.next, .previous {
  margin: 0; }
  .next.disabled:hover, .previous.disabled:hover {
    background: transparent; }
  .next.disabled a, .previous.disabled a {
    opacity: 0.5;
    cursor: not-allowed; }
  .next a, .previous a {
    display: flex;
    align-items: center;
    justify-content: center; }
    .next a svg, .previous a svg {
      font-size: 20px;
      fill: #171717; }

.trk_trader_details .transactions_columns {
  width: 100%;
  overflow: auto; }
  .trk_trader_details .transactions_columns .table_header {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .trk_trader_details .transactions_columns .table_header .table_row {
      min-height: 36px; }
    .trk_trader_details .transactions_columns .table_header .row_item {
      display: flex;
      align-items: center;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear; }
      .trk_trader_details .transactions_columns .table_header .row_item p {
        display: flex;
        align-items: center; }
      .trk_trader_details .transactions_columns .table_header .row_item .down {
        margin-left: 5px;
        margin-bottom: -1px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 6px solid #ffffff; }
      .trk_trader_details .transactions_columns .table_header .row_item .up {
        margin-top: -1px;
        margin-left: 5px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 6px solid #ffffff; }
        .trk_trader_details .transactions_columns .table_header .row_item .up.no_display {
          opacity: 0; }
  .trk_trader_details .transactions_columns .table_body .table_row {
    min-height: 40px; }
    .trk_trader_details .transactions_columns .table_body .table_row .row_item {
      color: #171717; }
  .trk_trader_details .transactions_columns .table_row {
    min-width: 855px; }
    .trk_trader_details .transactions_columns .table_row .row_item {
      word-break: break-word; }
      @media screen and (max-width: 47.9375em) {
        .trk_trader_details .transactions_columns .table_row .row_item {
          padding: 2px 8px;
          width: 11.1%; } }
    .trk_trader_details .transactions_columns .table_row > :first-child {
      width: 8%; }
      @media screen and (min-width: 48em) {
        .trk_trader_details .transactions_columns .table_row > :first-child {
          margin-left: 1%;
          width: 13%; } }
    .trk_trader_details .transactions_columns .table_row > :nth-child(2) {
      display: flex;
      justify-content: flex-end;
      width: 8%; }
      @media screen and (min-width: 48em) {
        .trk_trader_details .transactions_columns .table_row > :nth-child(2) {
          width: 11%; } }
    .trk_trader_details .transactions_columns .table_row > :nth-child(3) {
      display: flex;
      justify-content: flex-end; }
      @media screen and (min-width: 48em) {
        .trk_trader_details .transactions_columns .table_row > :nth-child(3) {
          width: 11%; } }
    .trk_trader_details .transactions_columns .table_row > :nth-child(4) {
      display: flex;
      justify-content: flex-end; }
      @media screen and (min-width: 48em) {
        .trk_trader_details .transactions_columns .table_row > :nth-child(4) {
          width: 11%; } }
    .trk_trader_details .transactions_columns .table_row > :nth-child(5) {
      display: flex;
      justify-content: flex-end; }
      @media screen and (min-width: 48em) {
        .trk_trader_details .transactions_columns .table_row > :nth-child(5) {
          width: 11%; } }
    .trk_trader_details .transactions_columns .table_row > :nth-child(6) {
      display: flex;
      justify-content: flex-end; }
      @media screen and (min-width: 48em) {
        .trk_trader_details .transactions_columns .table_row > :nth-child(6) {
          width: 11%; } }
    .trk_trader_details .transactions_columns .table_row > :nth-child(7) {
      display: flex;
      justify-content: flex-end; }
      @media screen and (min-width: 48em) {
        .trk_trader_details .transactions_columns .table_row > :nth-child(7) {
          width: 11%; } }
    .trk_trader_details .transactions_columns .table_row > :nth-child(8) {
      display: flex;
      justify-content: flex-end; }
      @media screen and (min-width: 48em) {
        .trk_trader_details .transactions_columns .table_row > :nth-child(8) {
          width: 11%; } }
    .trk_trader_details .transactions_columns .table_row > :last-child {
      display: flex;
      justify-content: flex-end; }
      @media screen and (min-width: 48em) {
        .trk_trader_details .transactions_columns .table_row > :last-child {
          width: 11%;
          margin-right: 1%; } }
  .trk_trader_details .transactions_columns .block_row {
    display: flex;
    flex-flow: column;
    justify-content: flex-end; }
    .trk_trader_details .transactions_columns .block_row > span {
      display: flex;
      justify-content: flex-end; }
    .trk_trader_details .transactions_columns .block_row > p {
      display: flex;
      justify-content: flex-end; }
  .trk_trader_details .transactions_columns .block_row_flex_start {
    display: flex;
    flex-flow: column; }
  .trk_trader_details .transactions_columns .table_total .table_row .row_item, .trk_trader_details .transactions_columns .table_total .table_row p, .trk_trader_details .transactions_columns .table_total .table_row span {
    font-family: "CourierBold", sans-serif; }

.dashboard_trader_wrapper .title {
  color: #3B3C40;
  font-family: "CourierBold", sans-serif;
  font-size: 32px;
  letter-spacing: -1px;
  margin-bottom: 30px; }

.dashboard_trader_wrapper .tab_item_trader {
  margin-bottom: 40px; }
  .dashboard_trader_wrapper .tab_item_trader button {
    color: #C8C8C8;
    font-size: 14px;
    line-height: 13px;
    margin-right: 24px;
    font-family: "CourierBold", sans-serif;
    background: transparent;
    cursor: pointer;
    text-transform: uppercase; }
    @media screen and (min-width: 48em) {
      .dashboard_trader_wrapper .tab_item_trader button {
        margin-right: 50px; } }
    .dashboard_trader_wrapper .tab_item_trader button:hover {
      color: #171717; }
  .dashboard_trader_wrapper .tab_item_trader .active {
    color: #171717;
    position: relative; }
    .dashboard_trader_wrapper .tab_item_trader .active:before {
      position: absolute;
      content: "";
      left: 0;
      bottom: -10px;
      width: 100%;
      height: 2px;
      background: #4A52CB; }

.split_wrapper .title {
  color: #3B3C40;
  font-family: "CourierBold", sans-serif;
  font-size: 32px;
  letter-spacing: -1px;
  line-height: 38px;
  display: flex;
  align-items: center; }

.split_wrapper .transactions_columns {
  width: 100%; }
  .split_wrapper .transactions_columns .table_header {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .split_wrapper .transactions_columns .table_header .table_row {
      height: 36px; }
    .split_wrapper .transactions_columns .table_header .row_item {
      display: flex;
      align-items: center;
      cursor: pointer;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear; }
      .split_wrapper .transactions_columns .table_header .row_item p {
        display: flex;
        align-items: center; }
      .split_wrapper .transactions_columns .table_header .row_item .down {
        margin-left: 5px;
        margin-bottom: -1px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 6px solid #ffffff; }
      .split_wrapper .transactions_columns .table_header .row_item .up {
        margin-top: -1px;
        margin-left: 5px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 6px solid #ffffff; }
        .split_wrapper .transactions_columns .table_header .row_item .up.no_display {
          opacity: 0; }
  .split_wrapper .transactions_columns .table_body .table_row {
    height: 40px; }
    .split_wrapper .transactions_columns .table_body .table_row .row_item {
      color: #171717; }
  .split_wrapper .transactions_columns .block_row {
    display: flex;
    flex-flow: column;
    justify-content: flex-end; }
    .split_wrapper .transactions_columns .block_row > span {
      display: flex;
      justify-content: flex-end; }
    .split_wrapper .transactions_columns .block_row > p {
      display: flex;
      justify-content: flex-end; }
  .split_wrapper .transactions_columns .block_row_flex_start {
    display: flex;
    flex-flow: column; }
  .split_wrapper .transactions_columns .trader_status {
    display: flex;
    align-items: center;
    color: #3B3C40;
    font-family: "CourierBold", sans-serif;
    letter-spacing: -0.5px; }
    .split_wrapper .transactions_columns .trader_status span {
      height: 8px;
      width: 8px;
      min-width: 8px;
      min-height: 8px;
      margin-right: 10px;
      border-radius: 50%; }
    .split_wrapper .transactions_columns .trader_status .on {
      background-color: #58BC7F; }
    .split_wrapper .transactions_columns .trader_status .alert {
      background-color: #D0021B; }
    .split_wrapper .transactions_columns .trader_status .off {
      background-color: #BCBCBC; }
  .split_wrapper .transactions_columns .table_total .table_row .row_item, .split_wrapper .transactions_columns .table_total .table_row p, .split_wrapper .transactions_columns .table_total .table_row span {
    font-family: "CourierBold", sans-serif; }

.split_wrapper .trk_tabs {
  margin-left: 100px; }
  .split_wrapper .trk_tabs button {
    color: #C8C8C8;
    font-size: 14px;
    line-height: 13px;
    margin-right: 50px;
    font-family: "CourierBold", sans-serif;
    background: transparent;
    cursor: pointer;
    text-transform: uppercase; }
    .split_wrapper .trk_tabs button:hover {
      color: #171717; }
  .split_wrapper .trk_tabs .active {
    color: #171717;
    position: relative; }
    .split_wrapper .trk_tabs .active:before {
      position: absolute;
      content: "";
      left: 0;
      bottom: -10px;
      width: 100%;
      height: 2px;
      background: #4A52CB; }

.split_wrapper .split_table {
  margin-top: 24px; }
  @media screen and (min-width: 62em) {
    .split_wrapper .split_table {
      margin-top: 50px; } }
  .split_wrapper .split_table h3 {
    font-size: 32px;
    letter-spacing: -1px;
    line-height: 38px;
    margin-bottom: 16px; }
    @media screen and (min-width: 62em) {
      .split_wrapper .split_table h3 {
        margin-bottom: 30px; } }

.split_trader_wrapper .title {
  color: #3B3C40;
  font-family: "CourierBold", sans-serif;
  font-size: 32px;
  letter-spacing: -1px;
  margin-bottom: 30px; }

.split_trader_wrapper .tab_item_trader {
  margin-bottom: 40px; }
  .split_trader_wrapper .tab_item_trader button {
    color: #C8C8C8;
    font-size: 14px;
    line-height: 13px;
    margin-right: 24px;
    font-family: "CourierBold", sans-serif;
    background: transparent;
    cursor: pointer;
    text-transform: uppercase; }
    @media screen and (min-width: 48em) {
      .split_trader_wrapper .tab_item_trader button {
        margin-right: 50px; } }
    .split_trader_wrapper .tab_item_trader button:hover {
      color: #171717; }
  .split_trader_wrapper .tab_item_trader .active {
    color: #171717;
    position: relative; }
    .split_trader_wrapper .tab_item_trader .active:before {
      position: absolute;
      content: "";
      left: 0;
      bottom: -10px;
      width: 100%;
      height: 2px;
      background: #4A52CB; }

.default_dialog_root .paper_custom_dialog {
  min-width: 840px;
  width: 100%;
  overflow: visible;
  overflow: initial; }

.dialog_wrapper {
  padding: 50px 60px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  border-radius: 5px;
  background-color: #FFFFFF; }

.ip_traders_table .transactions_columns {
  width: 100%; }
  .ip_traders_table .transactions_columns .table_header .table_row {
    height: 36px; }
  .ip_traders_table .transactions_columns .table_header .row_item {
    display: flex;
    align-items: center;
    cursor: pointer;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear; }
    .ip_traders_table .transactions_columns .table_header .row_item .down {
      margin-left: 5px;
      margin-bottom: -1px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 6px solid #ffffff; }
    .ip_traders_table .transactions_columns .table_header .row_item .up {
      margin-top: -1px;
      margin-left: 5px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 6px solid #ffffff; }
  .ip_traders_table .transactions_columns .table_body .table_row {
    height: 40px; }
    .ip_traders_table .transactions_columns .table_body .table_row:nth-of-type(2n) {
      background-color: transparent !important; }
    .ip_traders_table .transactions_columns .table_body .table_row .row_item {
      color: #171717; }
  .ip_traders_table .transactions_columns .table_row > :first-child {
    width: 15% !important; }
  .ip_traders_table .transactions_columns .table_row > :nth-child(2) {
    width: 6% !important;
    display: flex;
    justify-content: flex-end; }
  .ip_traders_table .transactions_columns .table_row > :nth-child(3) {
    width: 6% !important;
    display: flex;
    justify-content: flex-end; }
  .ip_traders_table .transactions_columns .table_row > :nth-child(4) {
    width: 6% !important;
    display: flex;
    justify-content: flex-end;
    margin-right: 2%; }
  .ip_traders_table .transactions_columns .table_row > :nth-child(5) {
    width: 6% !important;
    display: flex;
    justify-content: flex-end; }
  .ip_traders_table .transactions_columns .table_row > :nth-child(6) {
    width: 9% !important;
    display: flex;
    justify-content: flex-end; }
  .ip_traders_table .transactions_columns .table_row > :nth-child(7) {
    display: flex;
    justify-content: flex-end;
    width: 9% !important; }
  .ip_traders_table .transactions_columns .table_row > :nth-child(8) {
    display: flex;
    justify-content: flex-end;
    width: 9% !important; }
  .ip_traders_table .transactions_columns .table_row > :nth-child(9) {
    display: flex;
    justify-content: flex-end;
    width: 9% !important; }
  .ip_traders_table .transactions_columns .table_row > :nth-child(10) {
    display: flex;
    justify-content: flex-end;
    width: 9% !important; }
  .ip_traders_table .transactions_columns .table_row > :nth-child(11) {
    display: flex;
    justify-content: flex-end;
    width: 9% !important; }
  .ip_traders_table .transactions_columns .table_row > :last-child {
    width: 7% !important;
    display: flex;
    justify-content: flex-end; }
    .ip_traders_table .transactions_columns .table_row > :last-child button {
      display: flex;
      background: transparent; }
  .ip_traders_table .transactions_columns .table_row_server > :first-child {
    width: 21% !important; }
  .ip_traders_table .transactions_columns .table_row_server > :nth-child(2) {
    width: 21% !important; }
  .ip_traders_table .transactions_columns .table_row_server > :nth-child(3) {
    width: 13% !important; }
  .ip_traders_table .transactions_columns .table_row_server > :nth-child(4) {
    width: 8% !important;
    margin-right: 2%; }
  .ip_traders_table .transactions_columns .table_row_server > :nth-child(5) {
    width: 13% !important;
    justify-content: left !important; }
  .ip_traders_table .transactions_columns .table_row_server > :nth-child(6) {
    width: 13% !important; }
  .ip_traders_table .transactions_columns .table_row_server > :nth-child(7) {
    width: 13% !important; }
  .ip_traders_table .transactions_columns .table_row_server > :nth-child(8) {
    width: 10% !important; }
  .ip_traders_table .transactions_columns .table_row_server > :nth-child(9) {
    width: 10% !important; }
  .ip_traders_table .transactions_columns .table_row_server > :nth-child(10) {
    width: 17% !important;
    font-family: "CourierBold", sans-serif;
    display: flex;
    justify-content: flex-end !important;
    padding-right: 13px;
    color: #4A52CB !important; }
    .ip_traders_table .transactions_columns .table_row_server > :nth-child(10) .copy_icon {
      cursor: pointer;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear; }
      .ip_traders_table .transactions_columns .table_row_server > :nth-child(10) .copy_icon:hover {
        opacity: 0.5; }
      .ip_traders_table .transactions_columns .table_row_server > :nth-child(10) .copy_icon img {
        margin-right: 8px; }
  .ip_traders_table .transactions_columns .block_row {
    display: flex;
    flex-flow: column;
    justify-content: flex-end; }
    .ip_traders_table .transactions_columns .block_row span {
      display: flex;
      justify-content: flex-end; }
    .ip_traders_table .transactions_columns .block_row p {
      color: #9B9B9B;
      display: flex;
      justify-content: flex-end; }
  .ip_traders_table .transactions_columns .status {
    display: flex;
    align-items: center;
    color: #4A52CB;
    font-family: "CourierBold",sans-serif;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 17px;
    text-decoration: none; }
    .ip_traders_table .transactions_columns .status:hover {
      text-decoration: underline; }
    .ip_traders_table .transactions_columns .status span {
      height: 8px;
      width: 8px;
      min-width: 8px;
      min-height: 8px;
      margin-right: 10px;
      border-radius: 50%; }
    .ip_traders_table .transactions_columns .status .canceled {
      background: #e11e1e; }
    .ip_traders_table .transactions_columns .status .awaiting {
      background: #BCBCBC; }
    .ip_traders_table .transactions_columns .status .done {
      background: #58BC7F; }
  .ip_traders_table .transactions_columns .server_status {
    display: flex;
    align-items: center;
    color: #3B3C40;
    font-family: "CourierBold",sans-serif;
    letter-spacing: -0.5px; }
    .ip_traders_table .transactions_columns .server_status span {
      height: 8px;
      width: 8px;
      min-width: 8px;
      min-height: 8px;
      margin-right: 10px;
      border-radius: 50%; }
    .ip_traders_table .transactions_columns .server_status .creating {
      background-color: #66C6FF; }
    .ip_traders_table .transactions_columns .server_status .created {
      background-color: #58BC7F; }
    .ip_traders_table .transactions_columns .server_status .error {
      background-color: #D0021B; }
    .ip_traders_table .transactions_columns .server_status .alert {
      background-color: #FFB232; }
    .ip_traders_table .transactions_columns .server_status .deleting {
      background-color: #BCBCBC; }
  .ip_traders_table .transactions_columns .table_total .table_row .row_item {
    font-family: "CourierBold",sans-serif; }

.manual_select_wrapper {
  display: flex;
  align-items: center;
  position: relative; }
  .manual_select_wrapper > span {
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 17px;
    opacity: 0.5;
    margin-right: 16px; }
  .manual_select_wrapper .manual_select {
    font-family: "CourierRegular", sans-serif;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    color: #C8C8C8;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 17px;
    border-bottom: 1px solid #C8C8C8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 94px; }
    .manual_select_wrapper .manual_select .full_opacity {
      color: #171717; }
    .manual_select_wrapper .manual_select > img {
      -webkit-transform: scale(0.8) rotate(90deg);
              transform: scale(0.8) rotate(90deg);
      cursor: pointer;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear; }
      .manual_select_wrapper .manual_select > img.rotated {
        -webkit-transform: scale(0.8) rotate(270deg);
                transform: scale(0.8) rotate(270deg); }
  .manual_select_wrapper .manual_selector {
    position: absolute;
    right: 0;
    top: 42px;
    width: 145px;
    box-sizing: border-box;
    padding: 7px 16px;
    display: none;
    background-color: #FFFFFF;
    box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.5);
    z-index: 1000; }
    .manual_select_wrapper .manual_selector.is_open {
      display: flex;
      flex-flow: column; }
    .manual_select_wrapper .manual_selector > div {
      display: flex;
      align-items: center;
      padding: 10px 0;
      cursor: pointer; }
      .manual_select_wrapper .manual_selector > div .unchecked {
        height: 12px;
        width: 12px;
        border: 1px solid #171717;
        opacity: 0.3; }
      .manual_select_wrapper .manual_selector > div .checked {
        height: 14px;
        width: 14px; }
      .manual_select_wrapper .manual_selector > div .tag_name {
        font-size: 14px;
        letter-spacing: -0.5px;
        line-height: 18px;
        font-family: "CourierRegular", sans-serif;
        margin-left: 8px; }
        .manual_select_wrapper .manual_selector > div .tag_name.selected {
          font-family: "CourierBold", sans-serif; }

.ip_wrapper .title {
  padding-bottom: 30px;
  color: #3B3C40;
  font-family: "CourierBold",sans-serif;
  font-size: 32px;
  letter-spacing: -1px;
  line-height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .ip_wrapper .title .title_and_tabs {
    display: flex;
    align-items: center; }
  .ip_wrapper .title .menu {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .ip_wrapper .title .menu .manual_select_wrapper {
      margin-left: 64px; }
    .ip_wrapper .title .menu .add_new {
      margin-left: 64px;
      align-items: center;
      color: #171717;
      font-size: 14px;
      letter-spacing: -0.5px;
      line-height: 17px;
      display: flex;
      text-decoration: none; }
      .ip_wrapper .title .menu .add_new img {
        margin-right: 8px; }
    .ip_wrapper .title .menu .stop_block {
      margin-left: 32px;
      display: flex;
      align-items: center;
      cursor: pointer;
      background: transparent;
      font-size: 14px;
      letter-spacing: -0.5px; }
      .ip_wrapper .title .menu .stop_block img {
        margin-right: 8px; }

.ip_wrapper .transactions_columns {
  width: 100%; }
  .ip_wrapper .transactions_columns.trk_one .table_header {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .ip_wrapper .transactions_columns.trk_one .table_header .table_row {
      height: 36px; }
    .ip_wrapper .transactions_columns.trk_one .table_header .row_item {
      display: flex;
      align-items: center;
      cursor: pointer;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear; }
      .ip_wrapper .transactions_columns.trk_one .table_header .row_item p {
        display: flex;
        align-items: center; }
      .ip_wrapper .transactions_columns.trk_one .table_header .row_item .down {
        margin-left: 5px;
        margin-bottom: -1px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 6px solid #ffffff; }
      .ip_wrapper .transactions_columns.trk_one .table_header .row_item .up {
        margin-top: -1px;
        margin-left: 5px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 6px solid #ffffff; }
        .ip_wrapper .transactions_columns.trk_one .table_header .row_item .up.no_display {
          opacity: 0; }
  .ip_wrapper .transactions_columns.trk_one .table_body .table_row {
    height: 40px; }
    .ip_wrapper .transactions_columns.trk_one .table_body .table_row .row_item {
      color: #171717; }
  .ip_wrapper .transactions_columns.trk_one .table_row > :first-child {
    width: 13%;
    margin-left: 1%; }
    .ip_wrapper .transactions_columns.trk_one .table_row > :first-child a {
      color: #4A52CB;
      font-family: "CourierBold",sans-serif;
      letter-spacing: -0.5px;
      text-decoration: none; }
      .ip_wrapper .transactions_columns.trk_one .table_row > :first-child a:hover {
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear;
        text-decoration: underline; }
  .ip_wrapper .transactions_columns.trk_one .table_row > :nth-child(2) {
    width: 8%; }
  .ip_wrapper .transactions_columns.trk_one .table_row > :nth-child(3) {
    width: 7%;
    display: flex;
    justify-content: flex-end; }
  .ip_wrapper .transactions_columns.trk_one .table_row > :nth-child(4) {
    width: 10%;
    display: flex;
    justify-content: flex-end; }
  .ip_wrapper .transactions_columns.trk_one .table_row > :nth-child(5) {
    width: 10%;
    display: flex;
    justify-content: flex-end; }
  .ip_wrapper .transactions_columns.trk_one .table_row > :nth-child(6) {
    width: 10%;
    display: flex;
    justify-content: flex-end; }
  .ip_wrapper .transactions_columns.trk_one .table_row > :nth-child(7) {
    width: 10%;
    display: flex;
    justify-content: flex-end; }
  .ip_wrapper .transactions_columns.trk_one .table_row > :nth-child(8) {
    width: 10%;
    display: flex;
    justify-content: flex-end; }
  .ip_wrapper .transactions_columns.trk_one .table_row > :nth-child(9) {
    width: 10%;
    display: flex;
    justify-content: flex-end; }
  .ip_wrapper .transactions_columns.trk_one .table_row > :last-child {
    width: 10%;
    display: flex;
    justify-content: flex-end;
    margin-right: 1%; }
  .ip_wrapper .transactions_columns.trk_one .block_row {
    display: flex;
    flex-flow: column;
    justify-content: flex-end; }
    .ip_wrapper .transactions_columns.trk_one .block_row > span {
      display: flex;
      justify-content: flex-end; }
    .ip_wrapper .transactions_columns.trk_one .block_row > p {
      display: flex;
      justify-content: flex-end; }
  .ip_wrapper .transactions_columns.trk_one .block_row_flex_start {
    display: flex;
    flex-flow: column; }
  .ip_wrapper .transactions_columns.trk_one .trader_status {
    display: flex;
    align-items: center;
    color: #3B3C40;
    font-family: "CourierBold",sans-serif;
    letter-spacing: -0.5px; }
    .ip_wrapper .transactions_columns.trk_one .trader_status span {
      height: 8px;
      width: 8px;
      min-width: 8px;
      min-height: 8px;
      margin-right: 10px;
      border-radius: 50%; }
    .ip_wrapper .transactions_columns.trk_one .trader_status .on {
      background-color: #58BC7F; }
    .ip_wrapper .transactions_columns.trk_one .trader_status .alert {
      background-color: #D0021B; }
    .ip_wrapper .transactions_columns.trk_one .trader_status .off {
      background-color: #BCBCBC; }
  .ip_wrapper .transactions_columns.trk_one .table_total .table_row .row_item, .ip_wrapper .transactions_columns.trk_one .table_total .table_row p, .ip_wrapper .transactions_columns.trk_one .table_total .table_row span {
    font-family: "CourierBold",sans-serif; }

.ip_wrapper .trk_tabs {
  margin-left: 100px; }
  .ip_wrapper .trk_tabs button {
    color: #C8C8C8;
    font-size: 14px;
    line-height: 13px;
    margin-right: 50px;
    font-family: "CourierBold",sans-serif;
    background: transparent;
    cursor: pointer;
    text-transform: uppercase; }
    .ip_wrapper .trk_tabs button:hover {
      color: #171717; }
  .ip_wrapper .trk_tabs .active {
    color: #171717;
    position: relative; }
    .ip_wrapper .trk_tabs .active:before {
      position: absolute;
      content: "";
      left: 0;
      bottom: -10px;
      width: 100%;
      height: 2px;
      background: #4A52CB; }

.ip_stop_traders .title, .m_stop_traders .title {
  color: #3B3C40;
  font-size: 28px;
  letter-spacing: -1px;
  line-height: 34px;
  padding-bottom: 27px; }

.ip_stop_traders .descriptions, .m_stop_traders .descriptions {
  color: #9B9B9B;
  font-size: 14px;
  letter-spacing: -0.5px; }

.ip_stop_traders hr, .m_stop_traders hr {
  margin: 30px 0;
  height: 1px;
  width: 100%;
  background: #4A52CB; }

.ip_stop_traders .dialog_brn_wrapper, .m_stop_traders .dialog_brn_wrapper {
  display: flex;
  justify-content: center; }
  .ip_stop_traders .dialog_brn_wrapper button, .m_stop_traders .dialog_brn_wrapper button {
    margin: 0 25px;
    font-family: "CourierBold",sans-serif;
    letter-spacing: -0.63px;
    line-height: 24px;
    font-size: 20px;
    background: transparent; }
  .ip_stop_traders .dialog_brn_wrapper .btn_grey, .m_stop_traders .dialog_brn_wrapper .btn_grey {
    color: #C8C8C8; }
  .ip_stop_traders .dialog_brn_wrapper .btn_blue, .m_stop_traders .dialog_brn_wrapper .btn_blue {
    color: #4A52CB; }

.ip_trader_wrapper .title {
  color: #3B3C40;
  font-family: "CourierBold",sans-serif;
  font-size: 32px;
  letter-spacing: -1px;
  margin-bottom: 30px; }

.ip_trader_wrapper .tab_item_trader {
  margin-bottom: 40px; }
  .ip_trader_wrapper .tab_item_trader button {
    color: #C8C8C8;
    font-size: 14px;
    line-height: 13px;
    margin-right: 50px;
    font-family: "CourierBold",sans-serif;
    background: transparent;
    cursor: pointer;
    text-transform: uppercase; }
    .ip_trader_wrapper .tab_item_trader button:hover {
      color: #171717; }
  .ip_trader_wrapper .tab_item_trader .active {
    color: #171717;
    position: relative; }
    .ip_trader_wrapper .tab_item_trader .active:before {
      position: absolute;
      content: "";
      left: 0;
      bottom: -10px;
      width: 100%;
      height: 2px;
      background: #4A52CB; }

.m_traders_table .transactions_columns {
  width: 100%; }
  .m_traders_table .transactions_columns .table_header .row_item {
    display: flex;
    align-items: center;
    cursor: pointer;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear; }
    .m_traders_table .transactions_columns .table_header .row_item .down {
      margin-left: 5px;
      margin-bottom: -1px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 6px solid #ffffff; }
    .m_traders_table .transactions_columns .table_header .row_item .up {
      margin-top: -1px;
      margin-left: 5px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 6px solid #ffffff; }
  .m_traders_table .transactions_columns .table_body .table_row {
    height: 40px; }
    .m_traders_table .transactions_columns .table_body .table_row:nth-of-type(2n) {
      background-color: transparent !important; }
    .m_traders_table .transactions_columns .table_body .table_row .row_item {
      color: #171717; }
  .m_traders_table .transactions_columns .table_row > :first-child {
    width: 100% !important;
    justify-content: flex-start !important; }
  .m_traders_table .transactions_columns .status {
    display: flex;
    align-items: center;
    color: #4A52CB;
    font-family: "CourierBold",sans-serif;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 17px;
    text-decoration: none; }
    .m_traders_table .transactions_columns .status:hover {
      text-decoration: underline; }
    .m_traders_table .transactions_columns .status span {
      height: 8px;
      width: 8px;
      min-width: 8px;
      min-height: 8px;
      margin-right: 10px;
      border-radius: 50%; }
    .m_traders_table .transactions_columns .status .canceled {
      background: #e11e1e; }
    .m_traders_table .transactions_columns .status .awaiting {
      background: #BCBCBC; }
    .m_traders_table .transactions_columns .status .done {
      background: #58BC7F; }

.m_wrapper .title {
  color: #3B3C40;
  font-family: "CourierBold",sans-serif;
  font-size: 32px;
  letter-spacing: -1px;
  line-height: 38px;
  display: flex;
  align-items: center; }

.m_wrapper .title_and_tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px; }

.m_wrapper .menu {
  display: flex;
  justify-content: flex-end;
  align-items: center; }
  .m_wrapper .menu .add_new {
    margin-left: 64px;
    align-items: center;
    color: #171717;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 17px;
    display: flex;
    text-decoration: none; }
    .m_wrapper .menu .add_new img {
      margin-right: 8px; }
  .m_wrapper .menu .stop_block {
    margin-left: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: transparent;
    font-size: 14px;
    letter-spacing: -0.5px; }
    .m_wrapper .menu .stop_block img {
      margin-right: 8px; }

.m_wrapper .transactions_columns {
  width: 100%; }
  .m_wrapper .transactions_columns .table_header {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .m_wrapper .transactions_columns .table_header .table_row {
      height: 36px; }
    .m_wrapper .transactions_columns .table_header .row_item {
      display: flex;
      align-items: center;
      cursor: pointer;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear; }
      .m_wrapper .transactions_columns .table_header .row_item p {
        display: flex;
        align-items: center; }
      .m_wrapper .transactions_columns .table_header .row_item .down {
        margin-left: 5px;
        margin-bottom: -1px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 6px solid #ffffff; }
      .m_wrapper .transactions_columns .table_header .row_item .up {
        margin-top: -1px;
        margin-left: 5px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 6px solid #ffffff; }
        .m_wrapper .transactions_columns .table_header .row_item .up.no_display {
          opacity: 0; }
  .m_wrapper .transactions_columns .table_body .table_row {
    height: 40px; }
    .m_wrapper .transactions_columns .table_body .table_row .row_item {
      color: #171717; }
  .m_wrapper .transactions_columns .table_row > :first-child {
    width: 13%;
    margin-left: 1%; }
    .m_wrapper .transactions_columns .table_row > :first-child a {
      color: #4A52CB;
      font-family: "CourierBold",sans-serif;
      letter-spacing: -0.5px;
      text-decoration: none; }
      .m_wrapper .transactions_columns .table_row > :first-child a:hover {
        -webkit-transition: all 0.2s linear;
        transition: all 0.2s linear;
        text-decoration: underline; }
  .m_wrapper .transactions_columns .table_row > :nth-child(2) {
    width: 8%; }
  .m_wrapper .transactions_columns .table_row > :nth-child(3) {
    width: 7%;
    display: flex;
    justify-content: flex-end; }
  .m_wrapper .transactions_columns .table_row > :nth-child(4) {
    width: 10%;
    display: flex;
    justify-content: flex-end; }
  .m_wrapper .transactions_columns .table_row > :nth-child(5) {
    width: 10%;
    display: flex;
    justify-content: flex-end; }
  .m_wrapper .transactions_columns .table_row > :nth-child(6) {
    width: 10%;
    display: flex;
    justify-content: flex-end; }
  .m_wrapper .transactions_columns .table_row > :nth-child(7) {
    width: 10%;
    display: flex;
    justify-content: flex-end; }
  .m_wrapper .transactions_columns .table_row > :nth-child(8) {
    width: 10%;
    display: flex;
    justify-content: flex-end; }
  .m_wrapper .transactions_columns .table_row > :nth-child(9) {
    width: 10%;
    display: flex;
    justify-content: flex-end; }
  .m_wrapper .transactions_columns .table_row > :last-child {
    width: 10%;
    display: flex;
    justify-content: flex-end;
    margin-right: 1%; }
  .m_wrapper .transactions_columns .block_row {
    display: flex;
    flex-flow: column;
    justify-content: flex-end; }
    .m_wrapper .transactions_columns .block_row > span {
      display: flex;
      justify-content: flex-end; }
    .m_wrapper .transactions_columns .block_row > p {
      display: flex;
      justify-content: flex-end; }
  .m_wrapper .transactions_columns .block_row_flex_start {
    display: flex;
    flex-flow: column; }
  .m_wrapper .transactions_columns .trader_status {
    display: flex;
    align-items: center;
    color: #3B3C40;
    font-family: "CourierBold",sans-serif;
    letter-spacing: -0.5px; }
    .m_wrapper .transactions_columns .trader_status span {
      height: 8px;
      width: 8px;
      min-width: 8px;
      min-height: 8px;
      margin-right: 10px;
      border-radius: 50%; }
    .m_wrapper .transactions_columns .trader_status .on {
      background-color: #58BC7F; }
    .m_wrapper .transactions_columns .trader_status .alert {
      background-color: #D0021B; }
    .m_wrapper .transactions_columns .trader_status .off {
      background-color: #BCBCBC; }
  .m_wrapper .transactions_columns .table_total .table_row .row_item, .m_wrapper .transactions_columns .table_total .table_row p, .m_wrapper .transactions_columns .table_total .table_row span {
    font-family: "CourierBold",sans-serif; }

.m_wrapper .trk_tabs {
  margin-left: 100px; }
  .m_wrapper .trk_tabs button {
    color: #C8C8C8;
    font-size: 14px;
    line-height: 13px;
    margin-right: 50px;
    font-family: "CourierBold",sans-serif;
    background: transparent;
    cursor: pointer;
    text-transform: uppercase; }
    .m_wrapper .trk_tabs button:hover {
      color: #171717; }
  .m_wrapper .trk_tabs .active {
    color: #171717;
    position: relative; }
    .m_wrapper .trk_tabs .active:before {
      position: absolute;
      content: "";
      left: 0;
      bottom: -10px;
      width: 100%;
      height: 2px;
      background: #4A52CB; }

.m_trader_wrapper .title {
  color: #3B3C40;
  font-family: "CourierBold",sans-serif;
  font-size: 32px;
  letter-spacing: -1px;
  margin-bottom: 30px; }

.m_trader_wrapper .tab_item_trader {
  margin-bottom: 40px; }
  .m_trader_wrapper .tab_item_trader button {
    color: #C8C8C8;
    font-size: 14px;
    line-height: 13px;
    margin-right: 50px;
    font-family: "CourierBold",sans-serif;
    background: transparent;
    cursor: pointer;
    text-transform: uppercase; }
    .m_trader_wrapper .tab_item_trader button:hover {
      color: #171717; }
  .m_trader_wrapper .tab_item_trader .active {
    color: #171717;
    position: relative; }
    .m_trader_wrapper .tab_item_trader .active:before {
      position: absolute;
      content: "";
      left: 0;
      bottom: -10px;
      width: 100%;
      height: 2px;
      background: #4A52CB; }

.inp_radio {
  width: 15px;
  height: 15px;
  padding-right: 8px; }
  .inp_radio > input[type="radio"] {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    border: none !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAATBJREFUKBWNUrGOgkAQdacjodHuahONIaGSQHIFtV+gP3Dlfc39xN0XWFuYQLAiIUYTKnptSOjA95T1Fgpxk2VnZ96bx8yOGvVWlmWTsizDpmmmDCmlctu2d47jXEyomJckSTYgnUD6AuGDmzZ9jJlYpS9RFP3CXgM8933/rP084zie4dgiSRIEwX8CZkMwL4rCMgmmzRiSN1pZpWk6rqqKCp99JZNIu1Xeo+a5gBTCdxgikUgMf5fNE9TE7h0ZeGcBfwR52unqO0SNEbBzXBbaMXQCv4BqLpZl7QBetoW/5BEDkseBENd1ryLyjUynoedA1i2xnKLOADAbgqt+h6nExIj96QF4EvmPfNy6rn9gHrB1p1n/kkqe53G67qtDpKcdiBDq9yFn89gHlvSgPL4379ScoiQDCYMAAAAASUVORK5CYII=) no-repeat; }
  .inp_radio > input[type="radio"]:checked {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border: none !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADqADAAQAAAABAAAADgAAAAC98Dn6AAABaUlEQVQoFYVSPUvEQBDNbiEE0miqqw88joNUFxKwSO0v0MrO0vo6Cztr/4GV/gLrK4SEXBUIxwmpUlhFm0DAIvG9JHPu2dzC7s7HezM7s6OsfyvP87O6rqOu66Z0KaUKx3HWi8Xiy4RqU0nT9BqkHUi3IEy4KdNGn4lVosRx/AL5CuBZEAQfYuedJMk5rjcEScMw/AvAaHAWZVnaJsGU6UPwTjKrLMtOm6ZhhgtmQi0nVVU9IPoNiXjBs+u696j9Z8z8jppnGqQI/o08byStYJtwI8CKNsgWMXwum6cRkd3b0sElmQZtOE0b8Fvo04OumuBjsga7AGguQNYkstymDfg59ELbtr0GYDkWbrERcDzC9slNmTYGIQa6z4GgbrHFbPWx7+CX7b+jZ+LgADAaxEvpsPiYCU/cQX+VAdhPDkGM1rbtE8QNtnSa9S+11ne+73O6+nVApGUciAjZ+yFn89gHz/O+B8pw/gLao8ZGMK0BRQAAAABJRU5ErkJggg==) no-repeat; }

.create_m_trader_title {
  color: #3B3C40;
  font-family: "CourierBold", sans-serif;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -1px;
  padding-bottom: 40px;
  padding-top: 10px; }

.wrapper_create_m_trader {
  display: flex; }
  .wrapper_create_m_trader > .col_item {
    flex-grow: 1; }
    .wrapper_create_m_trader > .col_item > .block_item {
      margin: 0 0 20px 0;
      display: flex;
      flex-direction: column; }
      .wrapper_create_m_trader > .col_item > .block_item > p, .wrapper_create_m_trader > .col_item > .block_item > .switch_signal_wrapper > p {
        color: #171717;
        font-family: "CourierBold", sans-serif;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: -0.5px;
        line-height: 17px;
        text-transform: uppercase; }
      .wrapper_create_m_trader > .col_item > .block_item > div {
        display: flex;
        justify-content: space-between;
        min-height: 25px;
        margin: 4px 0;
        align-items: center;
        width: 330px; }
        .wrapper_create_m_trader > .col_item > .block_item > div > .custom_input_wrapper {
          width: 145px;
          margin-top: 0 !important; }
          .wrapper_create_m_trader > .col_item > .block_item > div > .custom_input_wrapper .custom_input {
            width: auto;
            border: none; }
        .wrapper_create_m_trader > .col_item > .block_item > div > label {
          color: #171717;
          font-family: "CourierRegular", sans-serif;
          font-size: 14px;
          letter-spacing: -0.5px;
          line-height: 17px;
          display: flex;
          align-items: inherit; }
        .wrapper_create_m_trader > .col_item > .block_item > div input {
          border: none;
          padding: 6px 0;
          border-bottom: 1px solid #C8C8C8;
          color: #171717 !important;
          font-family: "CourierRegular", sans-serif;
          font-size: 14px !important;
          letter-spacing: -0.5px;
          line-height: 17px;
          width: 145px; }
        .wrapper_create_m_trader > .col_item > .block_item > div select {
          padding: 6px;
          border-bottom: 1px solid #C8C8C8;
          color: #9e9e9e !important;
          font-family: "CourierRegular", sans-serif;
          font-size: 14px;
          letter-spacing: -0.5px;
          line-height: 17px;
          width: 145px; }
        .wrapper_create_m_trader > .col_item > .block_item > div > .group_input {
          display: flex;
          width: 145px; }
          .wrapper_create_m_trader > .col_item > .block_item > div > .group_input > .custom_input_wrapper {
            margin: 0 !important; }
            .wrapper_create_m_trader > .col_item > .block_item > div > .group_input > .custom_input_wrapper input {
              border-bottom: 1px solid #C8C8C8 !important; }
            .wrapper_create_m_trader > .col_item > .block_item > div > .group_input > .custom_input_wrapper > div {
              border: none !important;
              padding: 0 !important; }
          .wrapper_create_m_trader > .col_item > .block_item > div > .group_input .custom_input {
            width: 68px;
            margin-top: 0 !important; }
            .wrapper_create_m_trader > .col_item > .block_item > div > .group_input .custom_input input {
              border: none;
              margin: 0;
              padding: 0; }
      .wrapper_create_m_trader > .col_item > .block_item .switch_block {
        display: flex;
        justify-content: flex-start; }
        .wrapper_create_m_trader > .col_item > .block_item .switch_block .custom_radio_label > span:last-child {
          font-family: "CourierRegular", sans-serif;
          font-weight: 600; }
  .wrapper_create_m_trader .switch_signal {
    width: 145px; }
    .wrapper_create_m_trader .switch_signal .custom_radio_group label > span:last-child {
      font-family: "CourierRegular", sans-serif;
      font-size: 14px;
      letter-spacing: -0.5px; }

.form_create_m_trader .btn_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  padding-right: 60px; }

.form_create_m_trader .create_btn {
  color: #4A52CB;
  font-size: 20px;
  letter-spacing: -0.63px;
  font-family: "CourierBold", sans-serif;
  line-height: 24px;
  display: flex;
  align-items: center;
  background: transparent;
  cursor: pointer;
  text-transform: uppercase; }
  .form_create_m_trader .create_btn img {
    margin-left: 15px; }

.select_component {
  height: 100%; }
  .select_component ~ .date_wrapper {
    margin-left: 20px; }
  .select_component.select--is-disabled {
    opacity: 0.5; }
  .select_component .select__control--is-focused {
    box-shadow: none;
    border: 1px solid #65A3E8; }
    .select_component .select__control--is-focused .indicator_active svg {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
  .select_component .select__control {
    height: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #C8C8C8;
    color: #1A1A1A !important;
    font-family: "GothaProMed", sans-serif !important;
    font-size: 10px;
    border-radius: 0; }
    .select_component .select__control .select__value-container {
      padding: 0;
      font-family: "CourierRegular", sans-serif;
      font-size: 14px; }
    .select_component .select__control:hover {
      cursor: pointer;
      border: none;
      border-bottom: 1px solid #C8C8C8; }
    .select_component .select__control .select__placeholder {
      color: rgba(127, 143, 164, 0.5);
      margin: 0; }
    .select_component .select__control .select__single-value {
      color: #1A1A1A; }
    .select_component .select__control .select__input {
      color: #1A1A1A;
      font-family: "GothaProReg", sans-serif;
      font-size: 10px; }
      .select_component .select__control .select__input input {
        font-size: 12px; }
    .select_component .select__control .select__indicator-separator {
      background: none; }
    .select_component .select__control .select_indicator {
      display: flex;
      align-items: center;
      justify-content: center; }
      .select_component .select__control .select_indicator svg {
        fill: #1A1A1A;
        font-size: 14px; }
    .select_component .select__control .select__loading-indicator span {
      background-color: #7F8FA4; }
    .select_component .select__control .select_close_btn {
      display: flex;
      align-items: center;
      padding: 8px;
      justify-content: center;
      cursor: pointer; }
      .select_component .select__control .select_close_btn svg {
        fill: #1A1A1A;
        font-size: 14px; }
  .select_component .select__menu {
    z-index: 9999;
    box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.5);
    border-radius: 0; }
    .select_component .select__menu .select__menu-list {
      font-family: 'CourierRegular', sans-serif;
      font-size: 14px;
      color: #9B9B9B;
      background: transparent; }
      .select_component .select__menu .select__menu-list .select__option {
        background: transparent;
        color: #171717;
        cursor: pointer; }
        .select_component .select__menu .select__menu-list .select__option:hover {
          background-color: #F0F3FE; }
      .select_component .select__menu .select__menu-list .status_select {
        padding: 0 12px;
        display: flex;
        align-items: center;
        background: transparent; }
        .select_component .select__menu .select__menu-list .status_select:hover {
          background: transparent; }

.select_wrapper {
  height: 32px;
  width: 145px; }
  .select_wrapper.error {
    border-bottom: 1px solid red !important; }

.status_select {
  display: flex;
  align-items: center; }

.custom_input_select {
  border: 1px solid red !important; }

.quadrants_wrapper {
  margin-top: 10px !important;
  display: flex;
  flex-flow: column !important;
  align-items: flex-start !important; }
  .quadrants_wrapper .add_quadrant {
    font-size: 14px;
    font-family: "CourierBold", sans-serif;
    color: #4A52CB;
    letter-spacing: -0.5px;
    display: flex;
    align-items: center;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .quadrants_wrapper .add_quadrant:hover {
      opacity: 0.7; }
    .quadrants_wrapper .add_quadrant img {
      margin-right: 8px; }
  .quadrants_wrapper .quadrants_list {
    display: flex;
    flex-flow: column;
    width: 100%;
    margin-top: 10px; }
    .quadrants_wrapper .quadrants_list .quadrant {
      margin-bottom: 20px; }
      .quadrants_wrapper .quadrants_list .quadrant > div {
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .quadrants_wrapper .quadrants_list .quadrant > div > span {
          font-family: "CourierRegular", sans-serif;
          font-size: 14px;
          letter-spacing: -0.5px;
          line-height: 17px; }
        .quadrants_wrapper .quadrants_list .quadrant > div .fields {
          width: 145px;
          display: flex;
          align-items: center; }
          .quadrants_wrapper .quadrants_list .quadrant > div .fields > div {
            width: 100%;
            border-bottom: 1px solid #C8C8C8;
            padding: 4px 0;
            font-size: 14px;
            letter-spacing: -0.5px;
            line-height: 17px;
            margin-bottom: 4px; }
            .quadrants_wrapper .quadrants_list .quadrant > div .fields > div:nth-of-type(2) {
              margin-left: 15px; }
        .quadrants_wrapper .quadrants_list .quadrant > div.title_block {
          margin-bottom: 15px; }
          .quadrants_wrapper .quadrants_list .quadrant > div.title_block span {
            font-family: "CourierBold", sans-serif; }
          .quadrants_wrapper .quadrants_list .quadrant > div.title_block .controls {
            display: flex;
            align-items: center; }
            .quadrants_wrapper .quadrants_list .quadrant > div.title_block .controls .delete, .quadrants_wrapper .quadrants_list .quadrant > div.title_block .controls .edit {
              font-family: "CourierBold", sans-serif;
              -webkit-transition: all 0.3s linear;
              transition: all 0.3s linear;
              font-size: 14px;
              letter-spacing: -0.5px;
              line-height: 17px;
              cursor: pointer; }
              .quadrants_wrapper .quadrants_list .quadrant > div.title_block .controls .delete:hover, .quadrants_wrapper .quadrants_list .quadrant > div.title_block .controls .edit:hover {
                opacity: 0.7; }
            .quadrants_wrapper .quadrants_list .quadrant > div.title_block .controls .delete {
              color: #C8C8C8;
              margin-left: 20px; }
            .quadrants_wrapper .quadrants_list .quadrant > div.title_block .controls .edit {
              color: #4A52CB; }

.quadrants_dialog .title {
  color: #3B3C40;
  font-size: 28px;
  letter-spacing: -1px;
  line-height: 34px;
  margin-bottom: 30px; }

.quadrants_dialog .descriptions {
  color: #9B9B9B;
  font-size: 14px;
  letter-spacing: -0.5px; }

.quadrants_dialog .fields {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .quadrants_dialog .fields > div {
    width: 31%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .quadrants_dialog .fields > div input {
      font-family: "CourierRegular", sans-serif;
      background-color: #FFFFFF;
      color: #01397C;
      font-size: 14px;
      letter-spacing: -0.5px;
      line-height: 17px;
      border-bottom: 1px solid #C8C8C8;
      width: 55px;
      margin-left: 15px;
      padding: 6.5px 0; }
      .quadrants_dialog .fields > div input::-webkit-input-placeholder {
        color: #C8C8C8; }
      .quadrants_dialog .fields > div input::-moz-placeholder {
        color: #C8C8C8; }
      .quadrants_dialog .fields > div input::-ms-input-placeholder {
        color: #C8C8C8; }
      .quadrants_dialog .fields > div input::placeholder {
        color: #C8C8C8; }

.quadrants_dialog hr {
  margin: 50px 0 30px;
  height: 1px;
  width: 100%;
  background: #4A52CB; }

.quadrants_dialog .dialog_brn_wrapper {
  display: flex;
  justify-content: center; }
  .quadrants_dialog .dialog_brn_wrapper button {
    margin: 0 25px;
    font-family: "CourierBold",sans-serif;
    letter-spacing: -0.63px;
    line-height: 24px;
    font-size: 20px;
    background: transparent;
    text-transform: uppercase; }
  .quadrants_dialog .dialog_brn_wrapper .btn_grey {
    color: #C8C8C8; }
  .quadrants_dialog .dialog_brn_wrapper .btn_blue {
    color: #4A52CB; }
    .quadrants_dialog .dialog_brn_wrapper .btn_blue:disabled {
      opacity: 0.5; }

.rules_wrapper {
  margin-top: 10px !important;
  display: flex;
  flex-flow: column !important;
  align-items: flex-start !important; }
  .rules_wrapper .add_rule {
    font-size: 14px;
    font-family: "CourierBold", sans-serif;
    color: #4A52CB;
    letter-spacing: -0.5px;
    display: flex;
    align-items: center;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .rules_wrapper .add_rule:hover {
      opacity: 0.7; }
    .rules_wrapper .add_rule img {
      margin-right: 8px; }
  .rules_wrapper .rules_list {
    display: flex;
    flex-flow: column;
    width: 100%;
    margin-top: 10px; }
    .rules_wrapper .rules_list .rule {
      margin-bottom: 20px; }
      .rules_wrapper .rules_list .rule > div {
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .rules_wrapper .rules_list .rule > div .q1, .rules_wrapper .rules_list .rule > div .q2, .rules_wrapper .rules_list .rule > div .condition {
          width: 36%;
          border-bottom: 1px solid #C8C8C8;
          padding: 4px 0;
          font-size: 14px;
          letter-spacing: -0.5px;
          line-height: 17px;
          margin-bottom: 4px; }
        .rules_wrapper .rules_list .rule > div .condition {
          width: 20%; }
        .rules_wrapper .rules_list .rule > div.title_block {
          margin-bottom: 15px;
          letter-spacing: -0.5px; }
          .rules_wrapper .rules_list .rule > div.title_block span {
            font-family: "CourierBold", sans-serif;
            font-size: 14px; }
          .rules_wrapper .rules_list .rule > div.title_block .controls {
            display: flex;
            align-items: center; }
            .rules_wrapper .rules_list .rule > div.title_block .controls .delete, .rules_wrapper .rules_list .rule > div.title_block .controls .edit {
              font-family: "CourierBold", sans-serif;
              -webkit-transition: all 0.3s linear;
              transition: all 0.3s linear;
              font-size: 14px;
              letter-spacing: -0.5px;
              line-height: 17px;
              cursor: pointer; }
              .rules_wrapper .rules_list .rule > div.title_block .controls .delete:hover, .rules_wrapper .rules_list .rule > div.title_block .controls .edit:hover {
                opacity: 0.7; }
            .rules_wrapper .rules_list .rule > div.title_block .controls .delete {
              color: #C8C8C8;
              margin-left: 20px; }
            .rules_wrapper .rules_list .rule > div.title_block .controls .edit {
              color: #4A52CB; }

.rules_dialog .title {
  color: #3B3C40;
  font-size: 28px;
  letter-spacing: -1px;
  line-height: 34px;
  margin-bottom: 30px; }

.rules_dialog .descriptions {
  color: #9B9B9B;
  font-size: 14px;
  letter-spacing: -0.5px; }

.rules_dialog .fields {
  display: flex;
  align-items: center; }
  .rules_dialog .fields > div {
    display: flex;
    align-items: center;
    margin-right: 30px; }
    .rules_dialog .fields > div:nth-of-type(2n-1) {
      width: 120px; }
      .rules_dialog .fields > div:nth-of-type(2n-1) > div {
        width: 100%; }
    .rules_dialog .fields > div:nth-of-type(2) {
      width: 60px; }
      .rules_dialog .fields > div:nth-of-type(2) > div {
        width: 100%; }
    .rules_dialog .fields > div:nth-of-type(4) {
      display: flex;
      align-items: center;
      margin-right: 0; }
      .rules_dialog .fields > div:nth-of-type(4) > span {
        opacity: 0.5;
        font-size: 14px;
        letter-spacing: -0.5px;
        line-height: 17px;
        margin-right: 23px; }
      .rules_dialog .fields > div:nth-of-type(4) > div {
        width: 86px; }
        .rules_dialog .fields > div:nth-of-type(4) > div input {
          margin-left: 0;
          width: 100%; }
    .rules_dialog .fields > div input {
      font-family: "CourierRegular", sans-serif;
      background-color: #FFFFFF;
      color: #01397C;
      font-size: 14px;
      letter-spacing: -0.5px;
      line-height: 17px;
      border-bottom: 1px solid #C8C8C8;
      width: 55px;
      margin-left: 15px;
      padding: 6.5px 0; }
      .rules_dialog .fields > div input::-webkit-input-placeholder {
        color: #C8C8C8; }
      .rules_dialog .fields > div input::-moz-placeholder {
        color: #C8C8C8; }
      .rules_dialog .fields > div input::-ms-input-placeholder {
        color: #C8C8C8; }
      .rules_dialog .fields > div input::placeholder {
        color: #C8C8C8; }

.rules_dialog hr {
  margin: 50px 0 30px;
  height: 1px;
  width: 100%;
  background: #4A52CB; }

.rules_dialog .dialog_brn_wrapper {
  display: flex;
  justify-content: center; }
  .rules_dialog .dialog_brn_wrapper button {
    margin: 0 25px;
    font-family: "CourierBold",sans-serif;
    letter-spacing: -0.63px;
    line-height: 24px;
    font-size: 20px;
    background: transparent;
    text-transform: uppercase; }
  .rules_dialog .dialog_brn_wrapper .btn_grey {
    color: #C8C8C8; }
  .rules_dialog .dialog_brn_wrapper .btn_blue {
    color: #4A52CB; }
    .rules_dialog .dialog_brn_wrapper .btn_blue:disabled {
      opacity: 0.5;
      cursor: help; }

.create_m_trader_title {
  color: #3B3C40;
  font-family: "CourierBold", sans-serif;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -1px;
  padding-bottom: 40px;
  padding-top: 10px; }

.wrapper_create_m_trader {
  display: flex; }
  .wrapper_create_m_trader > .col_item {
    flex-grow: 1; }
    .wrapper_create_m_trader > .col_item > .block_item {
      margin: 0 0 20px 0;
      display: flex;
      flex-direction: column; }
      .wrapper_create_m_trader > .col_item > .block_item > p, .wrapper_create_m_trader > .col_item > .block_item > .switch_signal_wrapper > p {
        color: #171717;
        font-family: "CourierBold", sans-serif;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: -0.5px;
        line-height: 17px;
        text-transform: uppercase; }
      .wrapper_create_m_trader > .col_item > .block_item > div {
        display: flex;
        justify-content: space-between;
        min-height: 25px;
        margin: 4px 0;
        align-items: center;
        width: 330px; }
        .wrapper_create_m_trader > .col_item > .block_item > div > .custom_input_wrapper {
          width: 145px;
          margin-top: 0 !important; }
          .wrapper_create_m_trader > .col_item > .block_item > div > .custom_input_wrapper .custom_input {
            width: auto;
            border: none; }
        .wrapper_create_m_trader > .col_item > .block_item > div > label {
          color: #171717;
          font-family: "CourierRegular", sans-serif;
          font-size: 14px;
          letter-spacing: -0.5px;
          line-height: 17px;
          display: flex;
          align-items: inherit; }
        .wrapper_create_m_trader > .col_item > .block_item > div input {
          border: none;
          padding: 6px 0;
          border-bottom: 1px solid #C8C8C8;
          color: #171717 !important;
          font-family: "CourierRegular", sans-serif;
          font-size: 14px !important;
          letter-spacing: -0.5px;
          line-height: 17px;
          width: 145px; }
        .wrapper_create_m_trader > .col_item > .block_item > div .non_edit_value {
          display: flex;
          font-size: 14px;
          letter-spacing: -0.5px;
          line-height: 17px;
          padding: 5px 0;
          border-bottom: 1px solid #C8C8C8; }
          .wrapper_create_m_trader > .col_item > .block_item > div .non_edit_value.width_145 {
            width: 145px; }
        .wrapper_create_m_trader > .col_item > .block_item > div .non_edit_signal {
          display: flex;
          font-size: 14px;
          width: 145px;
          letter-spacing: -0.5px;
          line-height: 17px; }
        .wrapper_create_m_trader > .col_item > .block_item > div select {
          padding: 6px;
          border-bottom: 1px solid #C8C8C8;
          color: #9e9e9e !important;
          font-family: "CourierRegular", sans-serif;
          font-size: 14px;
          letter-spacing: -0.5px;
          line-height: 17px;
          width: 145px; }
        .wrapper_create_m_trader > .col_item > .block_item > div > .group_input {
          display: flex;
          width: 145px; }
          .wrapper_create_m_trader > .col_item > .block_item > div > .group_input > .custom_input_wrapper {
            margin: 0 !important; }
            .wrapper_create_m_trader > .col_item > .block_item > div > .group_input > .custom_input_wrapper input {
              border-bottom: 1px solid #C8C8C8 !important; }
            .wrapper_create_m_trader > .col_item > .block_item > div > .group_input > .custom_input_wrapper > div {
              border: none !important;
              padding: 0 !important; }
          .wrapper_create_m_trader > .col_item > .block_item > div > .group_input .custom_input {
            width: 68px;
            margin-top: 0 !important; }
            .wrapper_create_m_trader > .col_item > .block_item > div > .group_input .custom_input input {
              border: none;
              margin: 0;
              padding: 0; }
      .wrapper_create_m_trader > .col_item > .block_item .switch_block {
        display: flex;
        justify-content: flex-start; }
        .wrapper_create_m_trader > .col_item > .block_item .switch_block .custom_radio_label > span:last-child {
          font-family: "CourierRegular", sans-serif;
          font-weight: 600; }
  .wrapper_create_m_trader .switch_signal {
    width: 145px; }
    .wrapper_create_m_trader .switch_signal .custom_radio_group label > span:last-child {
      font-family: "CourierRegular", sans-serif;
      font-size: 14px;
      letter-spacing: -0.5px; }

.form_create_m_trader .btn_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  padding-right: 60px; }

.form_create_m_trader .create_btn {
  color: #4A52CB;
  font-size: 20px;
  letter-spacing: -0.63px;
  font-family: "CourierBold", sans-serif;
  line-height: 24px;
  display: flex;
  align-items: center;
  background: transparent;
  cursor: pointer;
  text-transform: uppercase; }
  .form_create_m_trader .create_btn img {
    margin-left: 15px; }

.servers_wrapper .row_item {
  flex-grow: 1;
  display: flex !important;
  justify-content: flex-start !important; }

.servers_wrapper .table_footer .row_item {
  color: #000000;
  font-size: 14px;
  letter-spacing: -0.5px; }
  .servers_wrapper .table_footer .row_item.bold {
    font-family: "CourierBold",sans-serif; }

.servers_wrapper .table_footer .table_row {
  background: none; }

.servers_wrapper .panel_dashboard {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px; }
  .servers_wrapper .panel_dashboard .title {
    color: #3B3C40;
    font-family: "CourierBold",sans-serif;
    font-size: 32px;
    letter-spacing: -1px;
    line-height: 38px; }
  .servers_wrapper .panel_dashboard .menu {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .servers_wrapper .panel_dashboard .menu .switch_net {
      margin-right: 133px;
      display: flex; }
      .servers_wrapper .panel_dashboard .menu .switch_net > form {
        display: flex;
        align-items: baseline; }
        .servers_wrapper .panel_dashboard .menu .switch_net > form p {
          margin-right: 24px; }
      .servers_wrapper .panel_dashboard .menu .switch_net .switch_span {
        cursor: pointer;
        -webkit-transition: all 0.3s linear;
        transition: all 0.3s linear;
        color: #171717;
        font-size: 14px;
        letter-spacing: -0.5px;
        line-height: 17px; }
      .servers_wrapper .panel_dashboard .menu .switch_net span {
        height: 12px;
        margin: auto 14px;
        display: flex;
        background: #171717;
        width: 1px; }
    .servers_wrapper .panel_dashboard .menu .archived_block {
      display: flex;
      align-items: center; }
      .servers_wrapper .panel_dashboard .menu .archived_block button {
        opacity: 0.3;
        background: transparent;
        color: #171717;
        font-size: 14px;
        letter-spacing: -0.5px;
        line-height: 17px; }
        .servers_wrapper .panel_dashboard .menu .archived_block button img {
          margin-right: 6px; }
      .servers_wrapper .panel_dashboard .menu .archived_block span {
        height: 12px;
        margin: auto 14px;
        display: flex;
        background: #171717;
        width: 1px; }
      .servers_wrapper .panel_dashboard .menu .archived_block .active {
        opacity: 1; }
    .servers_wrapper .panel_dashboard .menu .add_new {
      margin-left: 100px;
      align-items: center;
      color: #171717;
      font-size: 14px;
      letter-spacing: -0.5px;
      line-height: 17px;
      display: flex;
      text-decoration: none; }
      .servers_wrapper .panel_dashboard .menu .add_new img {
        margin-right: 8px; }
    .servers_wrapper .panel_dashboard .menu .stop_block {
      margin-left: 50px;
      display: flex;
      align-items: center;
      cursor: pointer;
      background: transparent;
      font-size: 14px;
      letter-spacing: -0.5px; }
      .servers_wrapper .panel_dashboard .menu .stop_block img {
        margin-right: 8px; }

.servers_wrapper .transactions_columns {
  width: 100%; }
  .servers_wrapper .transactions_columns .table_header .row_item {
    display: flex;
    align-items: center;
    cursor: pointer;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear; }
    .servers_wrapper .transactions_columns .table_header .row_item .down {
      margin-left: 5px;
      margin-bottom: -1px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 6px solid #ffffff; }
    .servers_wrapper .transactions_columns .table_header .row_item .up {
      margin-top: -1px;
      margin-left: 5px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 6px solid #ffffff; }
  .servers_wrapper .transactions_columns .table_body .table_row {
    height: 40px; }
    .servers_wrapper .transactions_columns .table_body .table_row:nth-of-type(2n) {
      background-color: transparent !important; }
    .servers_wrapper .transactions_columns .table_body .table_row .row_item {
      color: #171717; }
  .servers_wrapper .transactions_columns .table_row > :first-child {
    width: 15%; }
  .servers_wrapper .transactions_columns .table_row > :nth-child(2) {
    width: 6%;
    display: flex;
    justify-content: flex-end; }
  .servers_wrapper .transactions_columns .table_row > :nth-child(3) {
    width: 6%;
    display: flex;
    justify-content: flex-end; }
  .servers_wrapper .transactions_columns .table_row > :nth-child(4) {
    width: 6%;
    display: flex;
    justify-content: flex-end;
    margin-right: 2%; }
  .servers_wrapper .transactions_columns .table_row > :nth-child(5) {
    width: 6%;
    display: flex;
    justify-content: flex-end; }
  .servers_wrapper .transactions_columns .table_row > :nth-child(6) {
    width: 9%;
    display: flex;
    justify-content: flex-end; }
  .servers_wrapper .transactions_columns .table_row > :nth-child(7) {
    display: flex;
    justify-content: flex-end;
    width: 9%; }
  .servers_wrapper .transactions_columns .table_row > :nth-child(8) {
    display: flex;
    justify-content: flex-end;
    width: 9%; }
  .servers_wrapper .transactions_columns .table_row > :nth-child(9) {
    display: flex;
    justify-content: flex-end;
    width: 9%; }
  .servers_wrapper .transactions_columns .table_row > :nth-child(10) {
    display: flex;
    justify-content: flex-end;
    width: 9%; }
  .servers_wrapper .transactions_columns .table_row > :nth-child(11) {
    display: flex;
    justify-content: flex-end;
    width: 9%; }
  .servers_wrapper .transactions_columns .table_row > :last-child {
    width: 7%;
    display: flex;
    justify-content: flex-end; }
    .servers_wrapper .transactions_columns .table_row > :last-child button {
      display: flex;
      background: transparent; }
  .servers_wrapper .transactions_columns .table_row_server > :first-child {
    width: 20% !important;
    margin-left: 1%; }
  .servers_wrapper .transactions_columns .table_row_server > :nth-child(2) {
    width: 21% !important; }
  .servers_wrapper .transactions_columns .table_row_server > :nth-child(3) {
    width: 13% !important; }
  .servers_wrapper .transactions_columns .table_row_server > :nth-child(4) {
    width: 8% !important;
    margin-right: 2%; }
  .servers_wrapper .transactions_columns .table_row_server > :nth-child(5) {
    width: 13% !important;
    justify-content: left !important; }
  .servers_wrapper .transactions_columns .table_row_server > :nth-child(6) {
    width: 13% !important; }
  .servers_wrapper .transactions_columns .table_row_server > :nth-child(7) {
    width: 13% !important; }
  .servers_wrapper .transactions_columns .table_row_server > :nth-child(8) {
    width: 10% !important; }
  .servers_wrapper .transactions_columns .table_row_server > :nth-child(9) {
    width: 10% !important; }
  .servers_wrapper .transactions_columns .table_row_server > :nth-child(10) {
    width: 17% !important;
    font-family: "CourierBold", sans-serif;
    display: flex;
    justify-content: flex-end !important;
    padding-right: 13px;
    color: #4A52CB !important; }
    .servers_wrapper .transactions_columns .table_row_server > :nth-child(10) .copy_icon {
      cursor: pointer;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear; }
      .servers_wrapper .transactions_columns .table_row_server > :nth-child(10) .copy_icon:hover {
        opacity: 0.5; }
      .servers_wrapper .transactions_columns .table_row_server > :nth-child(10) .copy_icon img {
        margin-right: 8px; }
  .servers_wrapper .transactions_columns .block_row {
    display: flex;
    flex-flow: column;
    justify-content: flex-end; }
    .servers_wrapper .transactions_columns .block_row span {
      display: flex;
      justify-content: flex-end; }
    .servers_wrapper .transactions_columns .block_row p {
      color: #9B9B9B;
      display: flex;
      justify-content: flex-end; }
  .servers_wrapper .transactions_columns .status {
    display: flex;
    align-items: center;
    color: #4A52CB;
    font-family: "CourierBold",sans-serif;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 17px;
    text-decoration: none; }
    .servers_wrapper .transactions_columns .status:hover {
      text-decoration: underline; }
    .servers_wrapper .transactions_columns .status span {
      height: 8px;
      width: 8px;
      min-width: 8px;
      min-height: 8px;
      margin-right: 10px;
      border-radius: 50%; }
    .servers_wrapper .transactions_columns .status .canceled {
      background: #e11e1e; }
    .servers_wrapper .transactions_columns .status .awaiting {
      background: #BCBCBC; }
    .servers_wrapper .transactions_columns .status .done {
      background: #58BC7F; }
  .servers_wrapper .transactions_columns .server_status {
    display: flex;
    align-items: center;
    color: #3B3C40;
    font-family: "CourierBold",sans-serif;
    letter-spacing: -0.5px; }
    .servers_wrapper .transactions_columns .server_status span {
      height: 8px;
      width: 8px;
      min-width: 8px;
      min-height: 8px;
      margin-right: 10px;
      border-radius: 50%; }
    .servers_wrapper .transactions_columns .server_status .creating {
      background-color: #66C6FF; }
    .servers_wrapper .transactions_columns .server_status .created {
      background-color: #58BC7F; }
    .servers_wrapper .transactions_columns .server_status .error {
      background-color: #D0021B; }
    .servers_wrapper .transactions_columns .server_status .alert {
      background-color: #FFB232; }
    .servers_wrapper .transactions_columns .server_status .deleting {
      background-color: #BCBCBC; }
  .servers_wrapper .transactions_columns .table_total .table_row .row_item {
    font-family: "CourierBold",sans-serif; }

.servers_wrapper .stop_traders .title {
  color: #3B3C40;
  font-size: 28px;
  letter-spacing: -1px;
  line-height: 34px;
  padding-bottom: 27px; }

.servers_wrapper .stop_traders .descriptions {
  color: #9B9B9B;
  font-size: 14px;
  letter-spacing: -0.5px; }

.servers_wrapper .stop_traders hr {
  margin: 30px 0;
  height: 1px;
  width: 100%;
  background: #4A52CB; }

.servers_wrapper .stop_traders .dialog_brn_wrapper {
  display: flex;
  justify-content: center; }
  .servers_wrapper .stop_traders .dialog_brn_wrapper button {
    margin: 0 25px;
    font-family: "CourierBold",sans-serif;
    letter-spacing: -0.63px;
    line-height: 24px;
    font-size: 20px;
    background: transparent; }
  .servers_wrapper .stop_traders .dialog_brn_wrapper .btn_grey {
    color: #C8C8C8; }
  .servers_wrapper .stop_traders .dialog_brn_wrapper .btn_blue {
    color: #4A52CB; }

.servers_wrapper .no_items_dashboard .panel_dashboard .menu .archived_block {
  display: none; }

.servers_wrapper .no_items_dashboard .panel_dashboard .menu .stop_block {
  display: none; }

.servers_wrapper .no_items_dashboard .dashboard_table {
  display: none; }

.servers_wrapper .no_items_dashboard .no_items {
  display: flex; }

.servers_wrapper .no_items {
  font-size: 14px;
  letter-spacing: -0.5px;
  display: none; }

.creation_info {
  display: flex;
  justify-content: space-between;
  position: relative; }
  .creation_info input {
    color: #171717; }
  .creation_info > form {
    width: 100%;
    display: flex; }
    .creation_info > form > div:nth-child(2) {
      margin: 0 60px; }
    .creation_info > form > div {
      width: 33.3%;
      display: flex;
      flex-flow: column; }
      .creation_info > form > div > div {
        padding-bottom: 20px;
        display: flex;
        flex-flow: column; }
        .creation_info > form > div > div .title {
          font-family: "CourierBold",sans-serif;
          font-size: 14px;
          letter-spacing: -0.5px;
          padding-bottom: 10px;
          margin: 0; }
        .creation_info > form > div > div > div {
          display: flex;
          align-items: center; }
          .creation_info > form > div > div > div span {
            display: flex;
            width: 50%;
            font-size: 14px;
            letter-spacing: -0.5px;
            line-height: 17px; }
          .creation_info > form > div > div > div p {
            width: 50%;
            display: flex;
            font-size: 14px;
            letter-spacing: -0.5px;
            line-height: 17px;
            padding: 5px 0;
            border-bottom: 1px solid #C8C8C8; }
          .creation_info > form > div > div > div .custom_input_wrapper {
            width: 50%;
            display: flex;
            letter-spacing: -0.5px;
            line-height: 17px;
            padding: 0 !important;
            margin: 0 !important; }
            .creation_info > form > div > div > div .custom_input_wrapper input:disabled {
              opacity: 0.5; }
          .creation_info > form > div > div > div .custom_input_adorned_end {
            width: 100% !important;
            border-bottom: 1px solid #C8C8C8 !important;
            height: 30px !important;
            font-size: 14px !important;
            padding: 0 !important; }
            .creation_info > form > div > div > div .custom_input_adorned_end .custom_input_outline {
              padding: 0 !important; }
          .creation_info > form > div > div > div .select_wrapper {
            height: auto !important; }
          .creation_info > form > div > div > div .later {
            color: #898989; }
          .creation_info > form > div > div > div > div {
            display: flex;
            width: 50%; }
            .creation_info > form > div > div > div > div p:first-of-type, .creation_info > form > div > div > div > div .custom_input_wrapper:first-of-type {
              margin-right: 15px !important;
              width: 50%;
              display: flex; }
            .creation_info > form > div > div > div > div p {
              width: 50%;
              display: flex; }
    .creation_info > form .switch_block {
      display: flex;
      width: 100%; }
      .creation_info > form .switch_block label {
        cursor: pointer;
        margin-right: 30px;
        display: flex;
        font-family: "CourierBold",sans-serif;
        align-items: center; }
        .creation_info > form .switch_block label span {
          padding: 0; }
    .creation_info > form .creation_info_btn {
      color: #4a52cb;
      font-size: 20px;
      letter-spacing: -.63px;
      font-family: CourierBold,sans-serif;
      line-height: 24px;
      display: flex;
      align-items: center;
      background: transparent;
      cursor: pointer;
      text-transform: uppercase;
      position: absolute;
      bottom: -40px;
      right: 0; }
      .creation_info > form .creation_info_btn img {
        margin-left: 15px; }
    .creation_info > form .testClass {
      width: 100% !important;
      height: auto !important; }
      .creation_info > form .testClass .custom_radio_group {
        display: flex !important;
        flex-direction: column !important; }
        .creation_info > form .testClass .custom_radio_group > label {
          height: 31px !important; }
          .creation_info > form .testClass .custom_radio_group > label > span {
            width: auto !important; }
      .creation_info > form .testClass .custom_input_wrapper {
        width: 100% !important; }
  .creation_info .signal_wrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline; }
    .creation_info .signal_wrapper .status {
      height: 8px;
      width: 8px;
      min-width: 8px;
      min-height: 8px;
      border-radius: 50%;
      padding: 0;
      margin: 0 5px; }
    .creation_info .signal_wrapper .canceled {
      background: #D0021B; }
    .creation_info .signal_wrapper .awaiting {
      background: #BCBCBC; }
    .creation_info .signal_wrapper .done {
      background: #58BC7F; }
  .creation_info .switch_signal {
    width: 145px; }
    .creation_info .switch_signal .custom_radio_group label > span:last-child {
      font-family: Courier;
      font-size: 14px;
      letter-spacing: -0.5px; }
    .creation_info .switch_signal .custom_radio_group label {
      width: 45px;
      margin-right: 25px; }
      .creation_info .switch_signal .custom_radio_group label > span:first-child {
        padding: 0;
        margin-left: 10px;
        margin-bottom: 4px; }

.trader_details .transactions_columns {
  width: 100%; }
  .trader_details .transactions_columns .table_body .table_row {
    height: 20px; }
    .trader_details .transactions_columns .table_body .table_row .row_item {
      color: #171717; }
  .trader_details .transactions_columns .table_body .table_row > div:last-child {
    font-family: "CourierBold",sans-serif; }
  .trader_details .transactions_columns .table_row > :first-child {
    width: 17%; }
  .trader_details .transactions_columns .table_row > :nth-child(2) {
    width: 17%;
    display: flex;
    justify-content: flex-end; }
  .trader_details .transactions_columns .table_row > :nth-child(3) {
    width: 17%;
    display: flex;
    justify-content: flex-end; }
  .trader_details .transactions_columns .table_row > :nth-child(4) {
    width: 17%;
    display: flex;
    justify-content: flex-end; }
  .trader_details .transactions_columns .table_row > :nth-child(5) {
    width: 16%;
    display: flex;
    justify-content: flex-end; }
  .trader_details .transactions_columns .table_row > :last-child {
    width: 16%;
    display: flex;
    justify-content: flex-end; }
  .trader_details .transactions_columns .table_total .table_row .row_item {
    font-family: "CourierBold",sans-serif; }

.trader_details .trader_table {
  padding-bottom: 30px; }

.trader_details .info_components {
  display: flex;
  justify-content: space-between; }
  .trader_details .info_components > div:nth-child(2) {
    margin: 0 60px; }
  .trader_details .info_components > div {
    width: 33.3%;
    display: flex;
    flex-flow: column; }
    .trader_details .info_components > div .title {
      font-family: "CourierBold",sans-serif;
      font-size: 14px;
      letter-spacing: -0.5px;
      padding-bottom: 10px;
      text-transform: uppercase;
      font-weight: bold; }
    .trader_details .info_components > div > div {
      display: flex;
      align-items: center; }
      .trader_details .info_components > div > div span {
        display: flex;
        width: 50%;
        font-size: 14px;
        letter-spacing: -0.5px;
        line-height: 17px;
        padding-bottom: 5px; }
      .trader_details .info_components > div > div p {
        width: 50%;
        display: flex;
        font-size: 14px;
        letter-spacing: -0.5px;
        line-height: 17px;
        padding: 5px 0;
        border-bottom: 1px solid #C8C8C8; }
      .trader_details .info_components > div > div .status {
        height: 8px;
        width: 8px;
        min-width: 8px;
        min-height: 8px;
        margin: 10px 0;
        border-radius: 50%;
        padding: 0; }
      .trader_details .info_components > div > div .canceled {
        background: #D0021B; }
      .trader_details .info_components > div > div .awaiting {
        background: #BCBCBC; }
      .trader_details .info_components > div > div .done {
        background: #58BC7F; }
  .trader_details .info_components > div.info_col > div {
    flex-direction: column; }
  .trader_details .info_components > div.info_col > div > * {
    width: 100%;
    display: flex;
    align-items: baseline; }
  .trader_details .info_components > div.info_col > div .title {
    margin-bottom: 15px; }
  .trader_details .info_components > div.info_col > div:not(:first-child) {
    margin-top: 30px; }

/*.trader_details .info_components > div.info_col > div {
  flex-direction: column;
}

.trader_details .info_components > div.info_col > div > * {
  width: 100%;
  display: flex;
}

.trader_details .info_components > div.info_col > div .title {
  margin-bottom: 15px;
}

.trader_details .info_components > div.info_col > div:not(:first-child) {
  margin-top: 30px;
}*/

.item_trader_wrapper .title {
  color: #3B3C40;
  font-family: "CourierBold",sans-serif;
  font-size: 32px;
  letter-spacing: -1px;
  margin-bottom: 30px; }

.item_trader_wrapper .tab_item_trader {
  margin-bottom: 40px; }
  .item_trader_wrapper .tab_item_trader button {
    color: #C8C8C8;
    font-size: 14px;
    line-height: 13px;
    margin-right: 50px;
    font-family: "CourierBold",sans-serif;
    background: transparent;
    cursor: pointer;
    text-transform: uppercase; }
    .item_trader_wrapper .tab_item_trader button:hover {
      color: #171717; }
  .item_trader_wrapper .tab_item_trader .active {
    color: #171717;
    position: relative; }
    .item_trader_wrapper .tab_item_trader .active:before {
      position: absolute;
      content: "";
      left: 0;
      bottom: -10px;
      width: 100%;
      height: 2px;
      background: #4A52CB; }

.create_trader_title {
  color: #3B3C40;
  font-family: "CourierBold", sans-serif;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -1px;
  padding-bottom: 40px;
  padding-top: 10px; }

.warapper_create_trader {
  display: flex; }
  .warapper_create_trader > .col_item {
    flex-grow: 1; }
    .warapper_create_trader > .col_item > .block_item {
      margin: 0 0 20px 0;
      display: flex;
      flex-direction: column; }
      .warapper_create_trader > .col_item > .block_item > p, .warapper_create_trader > .col_item > .block_item > .switch_signal_wrapper > p {
        color: #171717;
        font-family: "CourierBold", sans-serif;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: -0.5px;
        line-height: 17px;
        text-transform: uppercase; }
      .warapper_create_trader > .col_item > .block_item > div {
        display: flex;
        justify-content: space-between;
        height: 25px;
        margin: 7px 0;
        align-items: center;
        width: 330px; }
        .warapper_create_trader > .col_item > .block_item > div > .custom_input_wrapper {
          width: 145px;
          margin-top: 0 !important; }
          .warapper_create_trader > .col_item > .block_item > div > .custom_input_wrapper .custom_input {
            width: auto;
            border: none; }
        .warapper_create_trader > .col_item > .block_item > div > label {
          color: #171717;
          font-family: "CourierRegular", sans-serif;
          font-size: 14px;
          letter-spacing: -0.5px;
          line-height: 17px;
          display: flex;
          align-items: inherit; }
        .warapper_create_trader > .col_item > .block_item > div input {
          border: none;
          padding: 6px 0;
          border-bottom: 1px solid #C8C8C8;
          color: #171717 !important;
          font-family: "CourierRegular", sans-serif;
          font-size: 14px !important;
          letter-spacing: -0.5px;
          line-height: 17px;
          width: 145px; }
        .warapper_create_trader > .col_item > .block_item > div select {
          padding: 6px;
          border-bottom: 1px solid #C8C8C8;
          color: #9e9e9e !important;
          font-family: "CourierRegular", sans-serif;
          font-size: 14px;
          letter-spacing: -0.5px;
          line-height: 17px;
          width: 145px; }
        .warapper_create_trader > .col_item > .block_item > div > .group_input {
          display: flex;
          width: 145px; }
          .warapper_create_trader > .col_item > .block_item > div > .group_input > .custom_input_wrapper {
            margin: 0 !important; }
            .warapper_create_trader > .col_item > .block_item > div > .group_input > .custom_input_wrapper input {
              border-bottom: 1px solid #C8C8C8 !important; }
            .warapper_create_trader > .col_item > .block_item > div > .group_input > .custom_input_wrapper > div {
              border: none !important;
              padding: 0 !important; }
          .warapper_create_trader > .col_item > .block_item > div > .group_input .custom_input {
            width: 68px;
            margin-top: 0 !important; }
            .warapper_create_trader > .col_item > .block_item > div > .group_input .custom_input input {
              border: none;
              margin: 0;
              padding: 0; }
      .warapper_create_trader > .col_item > .block_item .switch_block {
        display: flex;
        justify-content: flex-start; }
        .warapper_create_trader > .col_item > .block_item .switch_block .custom_radio_label > span:last-child {
          font-family: Courier;
          font-weight: 600; }
  .warapper_create_trader .switch_signal {
    width: 145px; }
    .warapper_create_trader .switch_signal .custom_radio_group label > span:last-child {
      font-family: Courier;
      font-size: 14px;
      letter-spacing: -0.5px; }

.form_create_trader .btn_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  padding-right: 60px; }

.form_create_trader .create_btn {
  color: #4A52CB;
  font-size: 20px;
  letter-spacing: -0.63px;
  font-family: "CourierBold", sans-serif;
  line-height: 24px;
  display: flex;
  align-items: center;
  background: transparent;
  cursor: pointer;
  text-transform: uppercase; }
  .form_create_trader .create_btn img {
    margin-left: 15px; }

.testClass {
  width: 330px !important;
  height: 70px !important; }
  .testClass .arrInput {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .testClass .custom_input {
    width: auto !important;
    border: none !important; }
  .testClass .custom_input_wrapper {
    margin: 0 !important;
    display: block !important; }
  .testClass .section_arr_input {
    height: inherit;
    justify-content: center; }
    .testClass .section_arr_input .custom_radio_group {
      height: inherit; }
      .testClass .section_arr_input .custom_radio_group label > span {
        padding: 0;
        padding-left: 8px; }
      .testClass .section_arr_input .custom_radio_group label span:nth-child(2) {
        font-family: "CourierRegular", sans-serif !important; }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important; }

.select__placeholder {
  color: #9e9e9e !important; }

.auth_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; }
  .auth_container .auth_wrapper {
    width: 100%;
    display: flex;
    flex-flow: column; }
    .auth_container .auth_wrapper .auth-block_head {
      margin: 0 auto;
      width: 500px;
      font-size: 56px; }
      .auth_container .auth_wrapper .auth-block_head > div {
        display: flex; }
      .auth_container .auth_wrapper .auth-block_head span {
        font-size: 56px;
        font-family: "CourierBold",sans-serif; }
      .auth_container .auth_wrapper .auth-block_head p {
        font-family: "CourierBold",sans-serif;
        font-size: 56px; }

.blue_line {
  box-sizing: border-box;
  padding: 45px 165px 75px 165px; }
  .blue_line hr {
    background: #4A52CB;
    width: 100%;
    height: 2px; }

.form_start {
  margin: 0 auto;
  width: 500px; }
  .form_start .btn_wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px; }
  .form_start .auth_btn {
    color: #4A52CB;
    font-size: 20px;
    letter-spacing: -0.63px;
    font-family: "CourierBold",sans-serif;
    line-height: 24px;
    display: flex;
    align-items: center;
    background: transparent;
    cursor: pointer;
    text-transform: uppercase; }
    .form_start .auth_btn img {
      margin-left: 15px; }

.output_error {
  color: red;
  width: 500px;
  margin: -64px auto;
  font-family: "CourierRegular", sans-serif;
  position: absolute; }

