.preloader-container{
  position: absolute;
  top: 50%;
  left:50%;
  transform:translate3d(-50%,-50%,0px);
}
.preloader{
  width: 100px;
  height: 100px;
  border-left: 2px solid red;
  border-radius: 50%;
  animation: round1 1.3s linear infinite,
  colorAnimation 12s ease-out  infinite;

}

@keyframes round1{
  0%{
    transform: rotate(0deg);
    transform-origin: center center;
  }
  100%{
    transform: rotate(360deg);
  }
}

@keyframes colorAnimation {
  0% {

    border-left: 2px solid #3498db;

  }
  15% {
    border-left: 2px solid #18bc9c;

  }
  30% {
    border-left: 2px solid #58C433;

  }
  45% {
    border-left: 2px solid #FF6853;

  }
  60% {
    border-left: 2px solid #e62739;

  }
  75% {
    border-left: 2px solid #f14ca7;

  }
  90% {
    border-left: 2px solid #9068be;

  }

}