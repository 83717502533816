.custom_input_wrapper {
  width: 100%;
  max-width: 300px;
  margin-top: 30px !important;
  .custom_input_label {
    font-family: "CourierRegular", sans-serif;
    color: #C2D1D9;
    font-size: 14px;
    transform: translate(0, 13px) scale(1);
    font-weight: 400;
    &.custom_input_label_focused {
      color: rgba(255,255,255,0.72);
    }
    &.custom_input_label_active {
      color: #01397C;
      transform: translate(0, -6px) scale(0.75);;
    }
    &.custom_input_label_error {
      color: #ff0000;
    }

  }
  .custom_input {
    //font-family: "Akrobat Regular", sans-serif;
    font-family: "CourierRegular", sans-serif;
    background-color: #FFFFFF;
    color: #01397C;
    font-size: 24px;
    //border-radius: 4px;
    max-height: 40px;
    width: 500px;
    border-bottom: 1px solid #C8C8C8;
    &:focus {
      background-color: #FFFFFF;
    }
    input {
      padding: 12px 0;
    }
    .custom_input_outline {
      border: none;
      border-radius: 0;
      legend {
        line-height: 0 !important;
      }
    }
    &.custom_input_focused {
      background-color: #FFFFFF;
      border-radius: 0;
      //border-bottom: 1px solid #3B3C40;
        .custom_input_outline {
          border: 1px solid #01397C;
          border-width: 0;
      }
    }
    &.custom_input_error, &.custom_input_error:hover {
      .custom_input_outline {
        border-bottom: 1px solid #ff0000 !important;
      }
    }
    &.custom_input_disabled {
      color: #334150;
    }
  }
  .custom_input_adorned_end {
    padding-right: 5px;
    & > div:last-of-type {
      margin-right: 8px;
      display: none;
      span {
        color: #C2D1D9;
        position: absolute;
        &.error_empty {
          right: 0;
          width: 100%;
          height: 15px;
          top: -10px;
        }
      }
      svg {
        fill: #ff0000;
        cursor: pointer;
      }
      .password_type_btn {
        padding: 0;
        width: 25px;
        height: 25px;
        &:hover {
          background-color: transparent;
        }
        svg {
          fill: #C2D1D9;
        }
      }
    }
  }
  .custom_input_adorned_start {
    & > div:first-of-type {
      margin-right: 0;
      span {
        color: #C2D1D9;
      }
    }
  }
}

