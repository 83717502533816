@import "src/style/mixins";

.trk_one {
  overflow: auto;
}

.trk_table_row {
  min-width: 465px;
  width: 100%;

  @include mq('md') {
    min-width: 900px;
  }

  .row_item {
    @include mq('sm-down') {
      padding-left: 8px;
      padding-right: 8px;
      width: 20%;
    }
  }

  & > :first-child {
    word-break: break-word;

    @include mq('md') {
      margin-left: 1%;
      width: 13%;
    }

    a {
      color: #4A52CB;
      font-family: "CourierBold", sans-serif;
      letter-spacing: -0.5px;
      text-decoration: none;

      &:hover {
        transition: all 0.2s linear;
        text-decoration: underline;
      }
    }
  }

  & > :nth-child(2) {
    display: flex;
    justify-content: flex-end;

    @include mq('md') {
      width: 10%;
    }
  }

  & > :nth-child(3) {
    display: flex;
    justify-content: flex-end;

    @include mq('md') {
      width: 10%;
    }
  }

  & > :nth-child(4) {
    display: flex;
    justify-content: flex-end;

    @include mq('md') {
      margin-right: 2%;
      width: 9%;
    }
  }

  & > :nth-child(5) {
    display: flex;
    justify-content: flex-end;
    text-align: right;

    @include mq('md') {
      justify-content: flex-start;
      width: 8%;
      text-align: left;
    }
  }

  & > :nth-child(6) {
    width: 7%;
    display: flex;
    justify-content: flex-end;
  }

  & > :nth-child(7) {
    width: 10%;
    display: flex;
    justify-content: flex-end;
  }

  & > :nth-child(8) {
    width: 10%;
    display: flex;
    justify-content: flex-end;
  }

  & > :nth-child(9) {
    width: 10%;
    display: flex;
    justify-content: flex-end;
  }

  & > :last-child {
    width: 9%;
    display: flex;
    justify-content: flex-end;
    margin-right: 1%;
  }

  & > :nth-child(n+6) {
    @include mq('sm-down') {
      display: none !important;
    }
  }
}