.trader_details{
  .transactions_columns {
    width: 100%;
    .table_body{
      .table_row{
        height: 20px;
        .row_item{
          color: #171717;
        }
      }
      .table_row {
        & > div{
          &:last-child{
            font-family: "CourierBold",sans-serif;
          }
        }
      }
    }
    .table_row {
      & > :first-child {
        width: 17%;
      }
      & > :nth-child(2) {
        width: 17%;
        display: flex;
        justify-content: flex-end;
      }
      & > :nth-child(3) {
        width: 17%;
        display: flex;
        justify-content: flex-end;
      }
      & > :nth-child(4) {
        width: 17%;
        display: flex;
        justify-content: flex-end;
      }
      & > :nth-child(5) {
        width: 16%;
        display: flex;
        justify-content: flex-end;
      }
      & > :last-child {
        width: 16%;
        display: flex;
        justify-content: flex-end;
      }

    }
    .table_total{
      .table_row{
        .row_item{
          font-family: "CourierBold",sans-serif;
        }
      }
    }
  }
  .trader_table{
    padding-bottom: 30px;
  }
  .info_components{
    display: flex;
    justify-content: space-between;
    >div:nth-child(2){
      margin: 0 60px;
    }
    >div{
      width: 33.3%;
      display: flex;
      flex-flow: column;
      .title{
        font-family:"CourierBold",sans-serif;
        font-size: 14px;
        letter-spacing: -0.5px;
        padding-bottom: 10px;
        text-transform: uppercase;
        font-weight: bold;
      }
      >div{
        display: flex;
        align-items: center;
        span{
          display: flex;
          width: 50%;
          font-size: 14px;
          letter-spacing: -0.5px;
          line-height: 17px;
          padding-bottom: 5px;
        }
        p{
          width: 50%;
          display: flex;
          font-size: 14px;
          letter-spacing: -0.5px;
          line-height: 17px;
          padding: 5px 0;
          border-bottom: 1px solid #C8C8C8;
        }
        .status{
          height: 8px;
          width: 8px;
          min-width: 8px;
          min-height: 8px;
          margin: 10px 0;
          border-radius: 50%;
          padding: 0;
        }
        .canceled{
          background:#D0021B;
        }
        .awaiting{
          background: #BCBCBC;
        }
        .done{
          background: #58BC7F;
        }

      }
    }
    & > div.info_col > div{
      flex-direction: column;
    }
    & > div.info_col > div > * {
      width: 100%;
      display: flex;
      align-items: baseline;
    }
    & > div.info_col > div .title {
      margin-bottom: 15px;
    }
    & > div.info_col > div:not(:first-child) {
      margin-top: 30px;
    }
  }
}


/*.trader_details .info_components > div.info_col > div {
  flex-direction: column;
}

.trader_details .info_components > div.info_col > div > * {
  width: 100%;
  display: flex;
}

.trader_details .info_components > div.info_col > div .title {
  margin-bottom: 15px;
}

.trader_details .info_components > div.info_col > div:not(:first-child) {
  margin-top: 30px;
}*/
