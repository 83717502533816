@import "src/style/mixins";

.dashboard_wrapper {
  .title_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      padding-bottom: 30px;
      color: #3B3C40;
      font-family: "CourierBold", sans-serif;
      font-size: 32px;
      letter-spacing: -1px;
      line-height: 38px;
      user-select: none;
      display: flex;
      flex-direction: column;
      gap: 16px 100px;

      @include mq('md') {
        flex-direction: row;
        align-items: center;
      }

      .trk_tabs {
        display: flex;

        button {
          color: #C8C8C8;
          font-size: 14px;
          line-height: 13px;
          margin-right: 16px;
          font-family: "CourierBold", sans-serif;
          background: transparent;
          cursor: pointer;
          text-transform: uppercase;

          @include mq('md') {
            margin-right: 30px;
          }

          &:hover {
            color: #171717;
          }
        }

        .active {
          color: #171717;
          position: relative;

          &:before {
            position: absolute;
            content: "";
            left: 0;
            bottom: -10px;
            width: 100%;
            height: 2px;
            background: #4A52CB;
          }
        }
      }
    }

    .tags_wrapper {
      display: flex;
      align-items: center;
      position: relative;
      padding-bottom: 30px;

      & > span {
        font-size: 14px;
        letter-spacing: -0.5px;
        line-height: 17px;
        opacity: 0.5;
        margin-right: 16px;
      }

      .tags_select {
        user-select: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #C8C8C8;
        font-size: 14px;
        letter-spacing: -0.5px;
        line-height: 17px;
        border-bottom: 1px solid #C8C8C8;
        width: max-content;

        .full_opacity {
          color: #171717;
        }

        & > img {
          transform: scale(0.8) rotate(90deg);
          cursor: pointer;
          -webkit-transition: all 0.3s linear;
          -moz-transition: all 0.3s linear;
          -ms-transition: all 0.3s linear;
          -o-transition: all 0.3s linear;
          transition: all 0.3s linear;

          &.rotated {
            transform: scale(0.8) rotate(270deg);
          }
        }
      }

      .tags_selector {
        position: absolute;
        right: 0;
        top: 42px;
        width: 145px;
        box-sizing: border-box;
        padding: 7px 16px;
        display: none;
        background-color: #FFFFFF;
        box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.5);
        z-index: 1000;

        &.is_open {
          display: flex;
          flex-flow: column;
        }

        & > div {
          display: flex;
          align-items: center;
          padding: 10px 0;
          cursor: pointer;

          .unchecked {
            height: 12px;
            width: 12px;
            border: 1px solid #171717;
            opacity: 0.3;
          }

          .checked {
            height: 14px;
            width: 14px;
          }

          .tag_name {
            font-size: 14px;
            letter-spacing: -0.5px;
            line-height: 18px;
            font-family: "CourierRegular", sans-serif;
            margin-left: 8px;

            &.selected {
              font-family: "CourierBold", sans-serif;
            }
          }
        }
      }
    }
  }

  .transactions_columns {
    width: 100%;

    .table_header {
      user-select: none;

      .table_row {
        height: 36px;
      }

      .row_item {
        display: flex;
        align-items: center;
        cursor: pointer;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;

        p {
          display: flex;
          align-items: center;
        }

        .down {
          margin-left: 5px;
          margin-bottom: -1px;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 6px solid #ffffff;
        }

        .up {
          margin-top: -1px;
          margin-left: 5px;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 6px solid #ffffff;

          &.no_display {
            opacity: 0;
          }
        }
      }
    }

    .table_body {
      .table_row {
        height: 40px;

        .row_item {
          color: #171717;
        }
      }
    }

    .block_row {
      display: flex;
      flex-flow: column;
      justify-content: flex-end;

      & > span {
        display: flex;
        justify-content: flex-end;
      }

      & > p {
        display: flex;
        justify-content: flex-end;
      }
    }

    .block_row_flex_start {
      display: flex;
      flex-flow: column;
    }

    .trader_status {
      display: flex;
      align-items: center;
      color: #3B3C40;
      font-family: "CourierBold", sans-serif;
      letter-spacing: -0.5px;

      span {
        min-width: 8px;
        min-height: 8px;
        height: 8px;
        width: 8px;
        margin-right: 10px;
        border-radius: 50%;
      }

      .on {
        background-color: #58BC7F;
      }

      .alert {
        background-color: #D0021B;
      }

      .off {
        background-color: #BCBCBC;
      }
    }

    .table_total {
      .table_row {
        .row_item, p, span {
          font-family: "CourierBold", sans-serif;
        }
      }
    }
  }
}