@import "./mixins";

body{
  color: #171717;
  font-family: 'CourierRegular',sans-serif;
}

.blue_text{
  color: #4A52CB;
}

.content_block {
  box-sizing: border-box;
  margin: 24px auto 40px;
  padding-left: 8px;
  padding-right: 8px;
  max-width: 1634px;
  width: 100%;

  @include mq('lg') {
    margin-top: 40px;
    margin-bottom: 80px;
    padding-left: 32px;
    padding-right: 32px;
  }
}

a{

  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  font-family: 'CourierRegular',sans-serif;
}
button{
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  font-family: 'CourierRegular',sans-serif;
}


.table_container{
  .table_header .table_header{
    display: flex;
  }
}

.table_header, .table_footer{
  .row_item{
    color: #FFFFFF;
    font-size: 14px;
    letter-spacing: -0.5px;
  }
  .table_row{
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 20px;
    background: #4A52CB;
  }
}

.table_body{
  &.table_total {
    .row_item {
      font-size: 14px;
    }
  }

  .row_item{
    color: #171717;
    font-size: 16px;
    letter-spacing: -0.5px;

    &:first-child {
      font-size: 14px;
    }

    @include mq('md') {
      font-size: 14px;
    }
  }

  .table_row{
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    &:nth-of-type(2n) {
      background-color: #F0F3FE;
    }
  }
}

.go_back{
  color: #4A52CB;
  font-family:"CourierBold",sans-serif;
  font-size: 14px;
  letter-spacing: -0.5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  width: max-content;
  img{
    margin-right: 5px;
  }
  &:hover{
    text-decoration: underline;
  }
}

.execute_button {
  color: #4A52CB;
  font-family:"CourierBold",sans-serif;
  font-size: 14px;
  letter-spacing: -0.5px;
  margin-left: 20px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s linear;
  &:hover {
    opacity: 0.7;
  }
  &.disabled {
    opacity: 0.5;
    cursor: default;
    &:hover {
      opacity: 0.5;
    }
  }
}

.loading_word::after {
  display: inline-block;
  animation: dotty steps(1,end) 0.2s infinite;
  content: '';
}

@keyframes dotty {
  0%   { content: ''; }
  25%  { content: '.'; }
  50%  { content: '..'; }
  75%  { content: '...'; }
  100% { content: ''; }
}

.p_with_opacity {
  p {
    color: #A5A5A5!important;
  }
}

.hide_on_mob {
  @include mq('sm-down') {
    display: none !important;
  }
}