.pagination-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 30px 0;
}

.pagination-list {
  display: flex;
  li {
    border-radius: 2px;
    min-width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s;
    &:hover{
      background: rgba(74, 82, 203, 0.1);
    }
    a{
      font-family: "CourierRegular", sans-serif;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 0 5px;
      box-sizing: border-box;
    }
  }
}


.pagination-link {
  cursor: pointer;
  background: transparent;
  color: #171717;
  text-decoration: none;
  text-align: center;
  line-height: 28px;
  height: 32px;
  min-width: 32px;
  font-size: 14px;
  &.pagination-link-active {
    background: #4A52CB;
    color: #ffffff;
    &:hover{
      opacity: 0.7;
    }
  }
}

.pagination-ellipsis {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  a {
    cursor: pointer;
    background: transparent;
    height: 32px;
    width: 32px;
    color: #171717;
    text-decoration: none;
    text-align: center;
    line-height: 28px;
  }
}
.next, .previous {
  margin: 0;

  &.disabled {
    &:hover{
      background: transparent;
    }
    a {
      opacity: 0.5;
      cursor: not-allowed;

    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 20px;
      fill: #171717;
    }
  }
}

