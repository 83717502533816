.inp_radio{
  width: 15px;
  height: 15px;
  padding-right: 8px;
  & > input[type="radio"]{
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    border: none !important;
    background: url("../../../../assets/image/inactive.png") no-repeat;
  }
  & > input[type="radio"]:checked{
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border: none !important;
    background: url("../../../../assets/image/active_blue.png") no-repeat;
  }
}

.custom_radio{
  display: flex !important;
  flex-direction: row !important;
}
.custom_radio_group{
  flex-direction: row !important;
}
.my_radio{
  padding: 0 !important;
  margin-left: 13px !important;
  padding-right: 7px !important;
}

