.inp_radio{
  width: 15px;
  height: 15px;
  padding-right: 8px;
  & > input[type="radio"]{
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    border: none !important;
    background: url("../../../../assets/image/inactive.png") no-repeat;
  }
  & > input[type="radio"]:checked{
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border: none !important;
    background: url("../../../../assets/image/active_black.png") no-repeat;
  }
}

