.select_component {
  height: 100%;

  & ~ .date_wrapper {
    margin-left: 20px;
  }

  &.select--is-disabled {
    opacity: 0.5;
  }

  .select__control--is-focused {
    box-shadow: none;
    border: 1px solid #65A3E8;


    .indicator_active {
      svg {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }

  .select__control {
    height: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #C8C8C8;
    color: #1A1A1A !important;
    font-family: "GothaProMed", sans-serif !important;
    font-size: 10px;
    border-radius: 0;

    .select__value-container {
      padding: 0;
      font-family: "CourierRegular", sans-serif;
      font-size: 14px;
    }


    &:hover {
      cursor: pointer;
      border: none;
      border-bottom: 1px solid #C8C8C8;
    }

    .select__placeholder {
      color: rgba(127, 143, 164, 0.5);
      margin: 0;
      //color: #7F8FA4;
    }

    .select__single-value {
      color: #1A1A1A;
    }

    .select__input {
      color: #1A1A1A;
      font-family: "GothaProReg", sans-serif;
      font-size: 10px;

      input {
        font-size: 12px;
      }
    }

    .select__indicator-separator {
      background: none;
    }

    .select_indicator {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: #1A1A1A;
        font-size: 14px;
      }
    }

    .select__loading-indicator {
      span {
        background-color: #7F8FA4;;
      }
    }

    .select_close_btn {
      display: flex;
      align-items: center;
      padding: 8px;
      justify-content: center;
      cursor: pointer;

      svg {
        fill: #1A1A1A;
        font-size: 14px;
      }
    }
  }

  .select__menu {
    z-index: 9999;
    box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.5);
    border-radius: 0;

    .select__menu-list {
      font-family: 'CourierRegular', sans-serif;
      font-size: 14px;
      color: #9B9B9B;
      background: transparent;

      .select__option {
        background: transparent;
        color: #171717;
        cursor: pointer;

        &:hover {
          background-color: #F0F3FE;
          //color: #1A1A1A;
        }
      }

      .status_select {
        padding: 0 12px;
        display: flex;
        align-items: center;
        background: transparent;

        &:hover {
          background: transparent;
          //color: #1A1A1A;
        }
      }

    }
  }
}

.select_wrapper {
  height: 32px;
  width: 145px;

  &.error {
    border-bottom: 1px solid red !important;
  }
}

.status_select {
  display: flex;
  align-items: center;
}

.custom_input_select {
  border: 1px solid red !important;
}



