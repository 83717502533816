// Convert px to em
$browser-context: 16;

@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }
  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1em;
}

// Media queries
$xs: 575px; // 35.938em / phone @ portrait
$sm: 576px; // 36em / phone @ landscape / small tablet (portrait
$md: 768px; // 48em / small tablet (landscape) / large tablet (portrait)
$lg: 992px; // 62em / large tablet (landscape) / small desktop
$xl: 1200px; // 75em / standard desktop
$xxl: 1400px; // 87.5em / large desktop

@mixin mq($point) {
  $media-query: false;

  @if $point == sm {
    $media-query: '(min-width: #{em($sm)})';
  } @else if $point == md {
    $media-query: '(min-width: #{em($md)})';
  } @else if $point == lg {
    $media-query: '(min-width: #{em($lg)})';
  } @else if $point == xl {
    $media-query: '(min-width: #{em($xl)})';
  } @else if $point == xxl {
    $media-query: '(min-width: #{em($xxl)})';
  }

  @if $point == xs-down {
    $media-query: '(max-width: #{em($sm - 1)})';
  } @else if $point == sm-down {
    $media-query: '(max-width: #{em($md - 1)})';
  } @else if $point == md-down {
    $media-query: '(max-width: #{em($lg - 1)})';
  } @else if $point == lg-down {
    $media-query: '(max-width: #{em($xl - 1)})';
  } @else if $point == xl-down {
    $media-query: '(max-width: #{em($xxl - 1)})';
  }

  @if $point == xs-only {
    $media-query: '(min-width: #{em($xs)}) and (max-width: #{em($sm - 1)})';
  } @else if $point == sm-only {
    $media-query: '(min-width: #{em($sm)}) and (max-width: #{em($md - 1)})';
  } @else if $point == md-only {
    $media-query: '(min-width: #{em($md)}) and (max-width: #{em($lg - 1)})';
  } @else if $point == lg-only {
    $media-query: '(min-width: #{em($lg)}) and (max-width: #{em($xl - 1)})';
  } @else if $point == xl-only {
    $media-query: '(min-width: #{em($xl)}) and (max-width: #{em($xxl - 1)})';
  }

  @if $media-query {
    @media screen and #{$media-query} {
      @content;
    }
  } @else {
    @warn 'A breakpoint named "#{$point}" is not available in the breakpoint map';
  }
}
