@import "src/style/mixins";

.trk_trader_details {
  .transactions_columns {
    width: 100%;
    overflow: auto;

    .table_header {
      user-select: none;

      .table_row {
        min-height: 36px;
      }

      .row_item {
        display: flex;
        align-items: center;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;

        p {
          display: flex;
          align-items: center;
        }

        .down {
          margin-left: 5px;
          margin-bottom: -1px;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 6px solid #ffffff;
        }

        .up {
          margin-top: -1px;
          margin-left: 5px;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 6px solid #ffffff;

          &.no_display {
            opacity: 0;
          }
        }
      }
    }

    .table_body {
      .table_row {
        min-height: 40px;

        .row_item {
          color: #171717;
        }
      }
    }

    .table_row {
      min-width: 855px;

      .row_item {
        word-break: break-word;

        @include mq('sm-down') {
          padding: 2px 8px;
          width: 11.1%;
        }
      }

      & > :first-child {
        width: 8%;

        @include mq('md') {
          margin-left: 1%;
          width: 13%;
        }
      }

      & > :nth-child(2) {
        display: flex;
        justify-content: flex-end;
        width: 8%;

        @include mq('md') {
          width: 11%;
        }
      }

      & > :nth-child(3) {
        display: flex;
        justify-content: flex-end;

        @include mq('md') {
          width: 11%;
        }
      }

      & > :nth-child(4) {
        display: flex;
        justify-content: flex-end;

        @include mq('md') {
          width: 11%;
        }
      }

      & > :nth-child(5) {
        display: flex;
        justify-content: flex-end;

        @include mq('md') {
          width: 11%;
        }
      }

      & > :nth-child(6) {
        display: flex;
        justify-content: flex-end;

        @include mq('md') {
          width: 11%;
        }
      }

      & > :nth-child(7) {
        display: flex;
        justify-content: flex-end;

        @include mq('md') {
          width: 11%;
        }
      }

      & > :nth-child(8) {
        display: flex;
        justify-content: flex-end;

        @include mq('md') {
          width: 11%;
        }
      }

      & > :last-child {
        display: flex;
        justify-content: flex-end;

        @include mq('md') {
          width: 11%;
          margin-right: 1%;
        }
      }
    }

    .block_row {
      display: flex;
      flex-flow: column;
      justify-content: flex-end;

      & > span {
        display: flex;
        justify-content: flex-end;
      }

      & > p {
        display: flex;
        justify-content: flex-end;
      }
    }

    .block_row_flex_start {
      display: flex;
      flex-flow: column;
    }

    .table_total {
      .table_row {
        .row_item, p, span {
          font-family: "CourierBold", sans-serif;
        }
      }
    }
  }
}