.servers_wrapper{
  .row_item{
    flex-grow: 1;
    //width: 100% !important;
    display: flex !important;
    justify-content: flex-start !important;
  }
  .table_footer{
    .row_item{
      color: #000000;
      font-size: 14px;
      letter-spacing: -0.5px;
      &.bold{
        font-family: "CourierBold",sans-serif;
      }
    }
    .table_row{
      background: none;
    }
  }

  .panel_dashboard{
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    .title{
      color: #3B3C40;
      font-family: "CourierBold",sans-serif;
      font-size: 32px;
      letter-spacing: -1px;
      line-height: 38px;
    }
    .menu{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .switch_net{
        margin-right: 133px;
        display: flex;
        & > form{
          display: flex;
          align-items: baseline;
          p{
            margin-right: 24px;
          }
        }
        .switch_span {
          cursor: pointer;
          -webkit-transition: all 0.3s linear;
          -moz-transition: all 0.3s linear;
          -ms-transition: all 0.3s linear;
          -o-transition: all 0.3s linear;
          transition: all 0.3s linear;
          color: #171717;
          font-size: 14px;
          letter-spacing: -0.5px;
          line-height: 17px;
        }
        span {
          height: 12px;
          margin: auto 14px;
          display: flex;
          background: #171717;
          width: 1px;
        }
      }
      .archived_block{
        display: flex;
        align-items: center;
        button{
          opacity: 0.3;
          background: transparent;
          color: #171717;
          font-size: 14px;
          letter-spacing: -0.5px;
          line-height: 17px;
          img{
            margin-right: 6px;
          }
        }
        span{
          height: 12px;
          margin: auto 14px;
          display: flex;
          background: #171717;
          width: 1px;
        }
        .active{
          opacity: 1;
        }
      }
      .add_new{
        margin-left: 100px;
        align-items: center;
        color: #171717;
        font-size: 14px;
        letter-spacing: -0.5px;
        line-height: 17px;
        display: flex;
        text-decoration: none;
        img{
          margin-right: 8px;
        }
      }
      .stop_block{
        margin-left: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;
        background: transparent;
        font-size: 14px;
        letter-spacing: -0.5px;
        img{
          margin-right: 8px;
        }
      }
    }
  }
  .transactions_columns {
    width: 100%;
    .table_header {
      .row_item {
        display: flex;
        align-items: center;
        cursor: pointer;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        .down{
          margin-left: 5px;
          margin-bottom: -1px;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 6px solid #ffffff;
        }
        .up{
          margin-top: -1px;
          margin-left: 5px;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 6px solid #ffffff;
        }
      }
    }
    .table_body{
      .table_row{
        height: 40px;
        &:nth-of-type(2n) {
          background-color: transparent!important;
        }
        .row_item{
          color: #171717;
        }
      }
    }
    .table_row {
      & > :first-child {
        width: 15%;
      }
      & > :nth-child(2) {
        width: 6%;
        display: flex;
        justify-content: flex-end;
      }
      & > :nth-child(3) {
        width: 6%;
        display: flex;
        justify-content: flex-end;
      }
      & > :nth-child(4) {
        width: 6%;
        display: flex;
        justify-content: flex-end;
        margin-right: 2%;
      }
      & > :nth-child(5) {
        width: 6%;
        display: flex;
        justify-content: flex-end;
      }
      & > :nth-child(6) {
        width: 9%;
        display: flex;
        justify-content: flex-end;
      }
      & > :nth-child(7) {
        display: flex;
        justify-content: flex-end;
        width: 9%;
      }
      & > :nth-child(8) {
        display: flex;
        justify-content: flex-end;
        width: 9%;
      }
      & > :nth-child(9) {
        display: flex;
        justify-content: flex-end;
        width: 9%;
      }
      & > :nth-child(10) {
        display: flex;
        justify-content: flex-end;
        width: 9%;
      }
      & > :nth-child(11) {
        display: flex;
        justify-content: flex-end;
        width: 9%;
      }
      & > :last-child {
        width: 7%;
        display: flex;
        justify-content: flex-end;
        button{
          display: flex;
          background: transparent;
        }
      }
    }
    .table_row_server {
      & > :first-child {
        width: 20%!important;
        margin-left: 1%;
      }
      & > :nth-child(2) {
        width: 21%!important;
      }
      & > :nth-child(3) {
        width: 13%!important;
      }
      & > :nth-child(4) {
        width: 8%!important;
        margin-right: 2%;
      }
      & > :nth-child(5) {
        width: 13%!important;
        justify-content: left!important;
      }
      & > :nth-child(6) {
        width: 13%!important;
      }
      & > :nth-child(7) {
        width: 13%!important;
      }
      & > :nth-child(8) {
        width: 10%!important;
      }
      & > :nth-child(9) {
        width: 10%!important;
      }
      & > :nth-child(10) {
        width: 17%!important;
        font-family: "CourierBold", sans-serif;
        display: flex;
        justify-content: flex-end!important;
        padding-right: 13px;
        color: #4A52CB!important;
        .copy_icon {
          cursor: pointer;
          -webkit-transition: all 0.3s linear;
          -moz-transition: all 0.3s linear;
          -ms-transition: all 0.3s linear;
          -o-transition: all 0.3s linear;
          transition: all 0.3s linear;
          &:hover {
            opacity: 0.5;
          }
          img {
            margin-right: 8px;
          }
        }
      }
    }
    .block_row{
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      span{
        display: flex;
        justify-content: flex-end;
      }
      p{
        color: #9B9B9B;
        display: flex;
        justify-content: flex-end;
      }

    }
    .status{
      display: flex;
      align-items: center;
      color: #4A52CB;
      font-family: "CourierBold",sans-serif;
      font-size: 14px;
      letter-spacing: -0.5px;
      line-height: 17px;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
      span{
        height: 8px;
        width: 8px;
        min-width: 8px;
        min-height: 8px;
        margin-right: 10px;
        border-radius: 50%;
      }
      .canceled{
        background: #e11e1e;
      }
      .awaiting{
        background: #BCBCBC;
      }
      .done{
        background: #58BC7F;
      }
    }
    .server_status{
      display: flex;
      align-items: center;
      color: #3B3C40;
      font-family: "CourierBold",sans-serif;
      letter-spacing: -0.5px;
      span{
        height: 8px;
        width: 8px;
        min-width: 8px;
        min-height: 8px;
        margin-right: 10px;
        border-radius: 50%;
      }
      .creating{
        background-color: #66C6FF;
      }
      .created{
        background-color: #58BC7F;
      }
      .error{
        background-color: #D0021B;
      }
      .alert{
        background-color: #FFB232;
      }
      .deleting{
        background-color: #BCBCBC;
      }
    }

    .table_total{
      .table_row{
        .row_item{
          font-family: "CourierBold",sans-serif;
        }
      }
    }
  }

  .stop_traders{
    .title{
      color: #3B3C40;
      font-size: 28px;
      letter-spacing: -1px;
      line-height: 34px;
      padding-bottom: 27px;
    }
    .descriptions{
      color: #9B9B9B;
      font-size: 14px;
      letter-spacing: -0.5px;
    }
    hr{
      margin: 30px 0;
      height: 1px;
      width: 100%;
      background: #4A52CB;
    }
    .dialog_brn_wrapper{
      display: flex;
      justify-content: center;
      button{
        margin:  0 25px;
        font-family: "CourierBold",sans-serif;
        letter-spacing: -0.63px;
        line-height: 24px;
        font-size: 20px;
        background: transparent;
      }
      .btn_grey{
        color: #C8C8C8;
      }
      .btn_blue{
        color: #4A52CB;
      }
    }
  }

  .no_items_dashboard{
    .panel_dashboard{
      .menu{
        .archived_block{
          display: none;
        }
        .stop_block{
          display: none;
        }
      }
    }
    .dashboard_table{
      display: none;
    }
    .no_items{
      display: flex;
    }
  }

  .no_items{
    font-size: 14px;
    letter-spacing: -0.5px;
    display: none;
  }
}