.auth_container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .auth_wrapper{
    width: 100%;
    display: flex;
    flex-flow: column;
    .auth-block_head{
      margin: 0 auto;
      width: 500px;
      font-size: 56px;
      >div{
        display: flex;
      }
      span{
        font-size: 56px;
        font-family: "CourierBold",sans-serif;

      }
      p{
        font-family: "CourierBold",sans-serif;
        font-size: 56px;
      }
    }
  }
}

.blue_line{
 box-sizing: border-box;
  padding: 45px 165px 75px 165px;
  hr{
    background:#4A52CB;
    width: 100%;
    height: 2px;
  }
}

.form_start{
  margin: 0 auto;
  width: 500px;
  .btn_wrapper{
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
  }
  .auth_btn{
    color: #4A52CB;
    font-size: 20px;
    letter-spacing: -0.63px;
    font-family: "CourierBold",sans-serif;

    line-height: 24px;
    display: flex;
    align-items: center;
    background: transparent;
    cursor: pointer;
    text-transform: uppercase;
    img{
      margin-left: 15px;
    }
  }
}

.output_error{
  color: red;
  width: 500px;
  margin: -64px auto;
  font-family: "CourierRegular", sans-serif;
  position: absolute;
}