.default_dialog_root {
  .paper_custom_dialog{
    min-width: 840px;
    width: 100%;
    overflow: initial;
  }

}

.dialog_wrapper {
  padding: 50px 60px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  border-radius: 5px;
  background-color: #FFFFFF;
}





