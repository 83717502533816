@import "src/style/mixins";

.header_wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 8px;
  padding-right: 8px;
  max-width: 1634px;
  width: 100%;
  min-height: 60px;
  user-select: none;

  @include mq('md') {
    min-height: 100px;
  }

  @include mq('lg') {
    padding-left: 32px;
    padding-right: 32px;
  }

  .logo {
    font-size: 18px;
    letter-spacing: -0.64px;
    line-height: 22px;
    display: flex;
    text-decoration: none;

    div {
      font-family: "CourierBold", sans-serif;
      color: #4A52CB;
    }

    span {
      font-family: "CourierBold", sans-serif;
      color: #171717;
    }
  }

  .log_out {
    font-size: 14px;
    display: flex;
    align-items: center;
    background: transparent;
    cursor: pointer;
    letter-spacing: -0.5px;
    margin-left: auto;

    img {
      @include mq('md') {
        margin-right: 10px;
      }
    }

    span {
      display: none;

      @include mq('md') {
        display: inline-block;
      }
    }
  }

  .header_wrapper_menu {
    @include mq('lg') {
      margin-left: 70px;
    }

    .active {
      opacity: 1;
    }

    & > a {
      font-family: "CourierRegular", sans-serif;
      margin-left: 24px;
      background: none;
      text-decoration: none;
      color: #171717;
      font-size: 14px;
      letter-spacing: -0.5px;
      line-height: 17px;
      font-weight: bold;
      opacity: 0.3;

      @include mq('md') {
        margin-left: 30px;
      }
    }
  }
}