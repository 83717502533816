.ip_wrapper{
  .title{
    padding-bottom: 30px;
    color: #3B3C40;
    font-family: "CourierBold",sans-serif;
    font-size: 32px;
    letter-spacing: -1px;
    line-height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title_and_tabs {
      display: flex;
      align-items: center;
    }
    .menu{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .manual_select_wrapper {
        margin-left: 64px;
      }
      .add_new{
        margin-left: 64px;
        align-items: center;
        color: #171717;
        font-size: 14px;
        letter-spacing: -0.5px;
        line-height: 17px;
        display: flex;
        text-decoration: none;
        img{
          margin-right: 8px;
        }
      }
      .stop_block{
        margin-left: 32px;
        display: flex;
        align-items: center;
        cursor: pointer;
        background: transparent;
        font-size: 14px;
        letter-spacing: -0.5px;
        img{
          margin-right: 8px;
        }
      }
    }
  }
  .transactions_columns {
    width: 100%;
    &.trk_one {
      .table_header {
        user-select: none;
        .table_row {
          height: 36px;
        }
        .row_item {
          display: flex;
          align-items: center;
          cursor: pointer;
          -webkit-transition: all 0.3s linear;
          -moz-transition: all 0.3s linear;
          -ms-transition: all 0.3s linear;
          -o-transition: all 0.3s linear;
          transition: all 0.3s linear;
          p {
            display: flex;
            align-items: center;
          }
          .down{
            margin-left: 5px;
            margin-bottom: -1px;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 6px solid #ffffff;
          }
          .up{
            margin-top: -1px;
            margin-left: 5px;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 6px solid #ffffff;
            &.no_display {
              opacity: 0;
            }
          }
        }
      }
      .table_body{
        .table_row{
          height: 40px;
          .row_item{
            color: #171717;
          }
        }
      }
      .table_row {
        & > :first-child {
          width: 13%;
          margin-left: 1%;
          a {
            color: #4A52CB;
            font-family: "CourierBold",sans-serif;
            letter-spacing: -0.5px;
            text-decoration: none;
            &:hover {
              transition: all 0.2s linear;
              text-decoration: underline;
            }
          }
        }
        & > :nth-child(2) {
          width: 8%;
        }
        & > :nth-child(3) {
          width: 7%;
          display: flex;
          justify-content: flex-end;
        }
        & > :nth-child(4) {
          width: 10%;
          display: flex;
          justify-content: flex-end;
        }
        & > :nth-child(5) {
          width: 10%;
          display: flex;
          justify-content: flex-end;
        }
        & > :nth-child(6) {
          width: 10%;
          display: flex;
          justify-content: flex-end;
        }
        & > :nth-child(7) {
          width: 10%;
          display: flex;
          justify-content: flex-end;
        }
        & > :nth-child(8) {
          width: 10%;
          display: flex;
          justify-content: flex-end;
        }
        & > :nth-child(9) {
          width: 10%;
          display: flex;
          justify-content: flex-end;
        }
        & > :last-child {
          width: 10%;
          display: flex;
          justify-content: flex-end;
          margin-right: 1%;
        }
      }
      .block_row {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        & > span {
          display: flex;
          justify-content: flex-end;
        }
        & > p {
          display: flex;
          justify-content: flex-end;
        }
      }
      .block_row_flex_start {
        display: flex;
        flex-flow: column;
      }
      .trader_status{
        display: flex;
        align-items: center;
        color: #3B3C40;
        font-family: "CourierBold",sans-serif;
        letter-spacing: -0.5px;
        span{
          height: 8px;
          width: 8px;
          min-width: 8px;
          min-height: 8px;
          margin-right: 10px;
          border-radius: 50%;
        }
        .on {
          background-color: #58BC7F;
        }
        .alert {
          background-color: #D0021B;
        }
        .off {
          background-color: #BCBCBC;
        }
      }
      .table_total{
        .table_row{
          .row_item, p, span{
            font-family: "CourierBold",sans-serif;
          }
        }
      }
    }
  }
  .trk_tabs{
    margin-left: 100px;
    button{
      color: #C8C8C8;
      font-size: 14px;
      line-height: 13px;
      margin-right: 50px;
      font-family:"CourierBold",sans-serif;
      background: transparent;
      cursor: pointer;
      text-transform: uppercase;
      &:hover{
        color: #171717;
      }
    }
    .active{
      color: #171717;
      position: relative;
      &:before{
        position: absolute;
        content: "";
        left: 0;
        bottom: -10px;
        width: 100%;
        height: 2px;
        background: #4A52CB;
      }
    }
  }
}

.ip_stop_traders, .m_stop_traders{
  .title{
    color: #3B3C40;
    font-size: 28px;
    letter-spacing: -1px;
    line-height: 34px;
    padding-bottom: 27px;
  }
  .descriptions{
    color: #9B9B9B;
    font-size: 14px;
    letter-spacing: -0.5px;
  }
  hr{
    margin: 30px 0;
    height: 1px;
    width: 100%;
    background: #4A52CB;
  }
  .dialog_brn_wrapper{
    display: flex;
    justify-content: center;
    button{
      margin:  0 25px;
      font-family: "CourierBold",sans-serif;
      letter-spacing: -0.63px;
      line-height: 24px;
      font-size: 20px;
      background: transparent;
    }
    .btn_grey{
      color: #C8C8C8;
    }
    .btn_blue{
      color: #4A52CB;
    }
  }
}