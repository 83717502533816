@import "src/style/mixins";

.split_trader_wrapper {
  .title {
    color: #3B3C40;
    font-family: "CourierBold", sans-serif;
    font-size: 32px;
    letter-spacing: -1px;
    margin-bottom: 30px;
  }

  .tab_item_trader {
    margin-bottom: 40px;

    button {
      color: #C8C8C8;
      font-size: 14px;
      line-height: 13px;
      margin-right: 24px;
      font-family: "CourierBold", sans-serif;
      background: transparent;
      cursor: pointer;
      text-transform: uppercase;

      @include mq('md') {
        margin-right: 50px;
      }

      &:hover {
        color: #171717;
      }
    }

    .active {
      color: #171717;
      position: relative;

      &:before {
        position: absolute;
        content: "";
        left: 0;
        bottom: -10px;
        width: 100%;
        height: 2px;
        background: #4A52CB;
      }
    }
  }

}