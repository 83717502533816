.quadrants_wrapper {
  margin-top: 10px!important;
  display: flex;
  flex-flow: column!important;
  align-items: flex-start!important;
  .add_quadrant {
    font-size: 14px;
    font-family: "CourierBold", sans-serif;
    color: #4A52CB;
    letter-spacing: -0.5px;
    display: flex;
    align-items: center;
    transition: all .3s linear;
    cursor: pointer;
    user-select: none;
    &:hover {
      opacity: 0.7;
    }
    img {
      margin-right: 8px;
    }
  }
  .quadrants_list {
    display: flex;
    flex-flow: column;
    width: 100%;
    margin-top: 10px;
    .quadrant {
      margin-bottom: 20px;
      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > span {
          font-family: "CourierRegular", sans-serif;
          font-size: 14px;
          letter-spacing: -0.5px;
          line-height: 17px;
        }
        .fields {
          width: 145px;
          display: flex;
          align-items: center;
          & > div {
            width: 100%;
            border-bottom: 1px solid #C8C8C8;
            padding: 4px 0;
            font-size: 14px;
            letter-spacing: -0.5px;
            line-height: 17px;
            margin-bottom: 4px;
            &:nth-of-type(2) {
              margin-left: 15px;
            }
          }
        }
        &.title_block {
          margin-bottom: 15px;
          span {
            font-family: "CourierBold", sans-serif;
          }
          .controls {
            display: flex;
            align-items: center;
            .delete, .edit {
              font-family: "CourierBold", sans-serif;
              transition: all 0.3s linear;
              font-size: 14px;
              letter-spacing: -0.5px;
              line-height: 17px;
              cursor: pointer;
              &:hover {
                opacity: 0.7;
              }
            }
            .delete {
              color: #C8C8C8;
              margin-left: 20px;
            }
            .edit {
              color: #4A52CB;
            }
          }
        }
      }
    }
  }
}

.quadrants_dialog {
  .title {
    color: #3B3C40;
    font-size: 28px;
    letter-spacing: -1px;
    line-height: 34px;
    margin-bottom: 30px;
  }
  .descriptions{
    color: #9B9B9B;
    font-size: 14px;
    letter-spacing: -0.5px;
  }
  .fields {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div {
      width: 31%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      input {
        font-family: "CourierRegular", sans-serif;
        background-color: #FFFFFF;
        color: #01397C;
        font-size: 14px;
        letter-spacing: -0.5px;
        line-height: 17px;
        border-bottom: 1px solid #C8C8C8;
        width: 55px;
        margin-left: 15px;
        padding: 6.5px 0;
        &::placeholder {
          color: #C8C8C8;
        }
      }
    }
  }
  hr {
    margin: 50px 0 30px;
    height: 1px;
    width: 100%;
    background: #4A52CB;
  }
  .dialog_brn_wrapper {
    display: flex;
    justify-content: center;
    button{
      margin:  0 25px;
      font-family: "CourierBold",sans-serif;
      letter-spacing: -0.63px;
      line-height: 24px;
      font-size: 20px;
      background: transparent;
      text-transform: uppercase;
    }
    .btn_grey {
      color: #C8C8C8;
    }
    .btn_blue {
      color: #4A52CB;
      &:disabled {
        opacity: 0.5;
      }
    }
  }
}